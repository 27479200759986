import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import MainContainer from "components/MainContainer/MainContainer";

import routes from "utils/constants/routes";
import { AuthProvider } from "context/AuthContext";
import { RequestDemoProvider } from "context/RequestDemoModalContext";

import "./index.css";

let router = createBrowserRouter(routes);

export default function App() {
  return (
    <AuthProvider>
      <RequestDemoProvider>
        <MainContainer>
          <RouterProvider
            router={router}
            fallbackElement={<p>Loading...</p>}
          />
        </MainContainer>
      </RequestDemoProvider>
    </AuthProvider>
  )
}