import React from 'react'

function StorageEncryptionIcon({
    className = "h-14 w-14 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 65 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M58.9118 45.9404V41.8868C58.7007 40.0279 57.8127 38.3117 56.4171 37.0656C55.0215 35.8196 53.2161 35.1309 51.3452 35.1309C49.4743 35.1309 47.6688 35.8196 46.2733 37.0656C44.8777 38.3117 43.9896 40.0279 43.7785 41.8868V45.9404C42.9372 45.9973 42.145 46.3572 41.5487 46.9535C40.9525 47.5497 40.5926 48.3419 40.5356 49.1832V58.6416C40.5393 59.5265 40.8742 60.378 41.4744 61.0282C42.0747 61.6785 42.8967 62.0804 43.7785 62.1547H58.6416C59.5265 62.151 60.378 61.8161 61.0283 61.2159C61.6785 60.6156 62.0804 59.7936 62.1547 58.9118V49.4535C62.1511 48.5685 61.8161 47.7171 61.2159 47.0668C60.6157 46.4166 59.7936 46.0147 58.9118 45.9404ZM47.2916 41.8868C47.3168 41.3863 47.443 40.8962 47.6625 40.4457C47.882 39.9953 48.1904 39.5939 48.5691 39.2657C48.9478 38.9375 49.3889 38.6893 49.866 38.536C50.3431 38.3827 50.8462 38.3275 51.3452 38.3737C51.8441 38.3275 52.3473 38.3827 52.8244 38.536C53.3014 38.6893 53.7426 38.9375 54.1212 39.2657C54.4999 39.5939 54.8083 39.9953 55.0278 40.4457C55.2474 40.8962 55.3735 41.3863 55.3987 41.8868V45.9404H47.2916V41.8868Z" />
            <path d="M64.8571 29.7262V5.40476C64.8571 3.97133 64.2877 2.59661 63.2741 1.58302C62.2605 0.569429 60.8858 0 59.4524 0L5.40476 0C3.97133 0 2.59661 0.569429 1.58302 1.58302C0.569429 2.59661 0 3.97133 0 5.40476L0 54.0476C0 55.481 0.569429 56.8558 1.58302 57.8694C2.59661 58.8829 3.97133 59.4524 5.40476 59.4524H35.131V54.0476H5.40476V5.40476H59.4524V29.7262" />
            <path d="M16.2143 10.8096H10.8096V16.2143H16.2143V10.8096ZM54.0477 10.8096H21.6191V16.2143H54.0477V10.8096ZM35.131 43.2381H21.6191V48.6429H35.131V43.2381ZM16.2143 43.2381H10.8096V48.6429H16.2143V43.2381ZM27.0239 21.6191H21.6191V27.0239H27.0239V21.6191ZM54.0477 21.6191H32.4286V27.0239H54.0477V21.6191ZM40.0169 32.4286H32.4286V37.8334H38.3739V36.4822C38.4023 34.9745 38.9876 33.5306 40.0169 32.4286ZM27.0239 32.4286H21.6191V37.8334H27.0239V32.4286Z" />
        </svg>
    )
}

export default StorageEncryptionIcon