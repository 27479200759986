import React, { useEffect, useState } from 'react'

import FAQ from 'components/FAQ/FAQ'
import ToggleButton from 'components/Button/ToggleButton'

// import ArrowRightIcon from 'assets/icons/ArrowRightIcon'
// import CheckCircleIcon from 'assets/icons/CheckCircleIcon'
import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowDownIcon'

import { products } from 'utils/constants/product'
import { addOns, reviews } from 'utils/constants/pricing'
import CheckIcon from 'assets/icons/CheckIcon'
import { useRequestDemoModal } from 'context/RequestDemoModalContext'
import ContactWhatsapp from 'components/ContactWhatsapp/ContactWhatsapp'
import Tooltip from 'components/Tooltip/Tooltip'

function Pricing() {
    const { isRequestDemoModalOpen, setRequestDemoModalOpen } = useRequestDemoModal();

    const [features, setFeatures] = useState([])
    const [annually, setAnnually] = useState(false)
    const [selectedPricings, setSelectedPricings] = useState(products[0]?.pricings)

    const [addOnsOpen, setAddOnsOpen] = useState([])

    const handleToggleAddOns = (index) => {
        if (addOnsOpen.includes(index)) {
            setAddOnsOpen(prev => prev.filter(i => i !== index))
        } else {
            setAddOnsOpen(prev => ([...prev, index]))
        }
    }

    const handleSelectProduct = (e) => {
        if (e?.target?.value) {
            setSelectedPricings(products[e.target.value]?.pricings)
        }
    }

    useEffect(() => {
        let features = []

        selectedPricings.forEach((item, i) => {
            item.features?.forEach((feature) => {
                if (!features?.find(f => f?.text === feature?.text)) {
                    features?.push(feature)
                }
            })
        })

        setFeatures(features)
    }, [selectedPricings])

    return (
        <div>
            <section className='bg-maplexxon-blue'>
                <div className='flex flex-col items-center max-w-main pt-4 pb-8 lg:py-16'>
                    <p className='text-base lg:text-xl text-center uppercase tracking-widest mb-4 text-white maplexxon-animation-translate-start'>Choose Solutions</p>
                    <div className='relative flex items-center mb-4 md:mb-8 maplexxon-animation-translate-start overflow-clip'>
                        <select
                            className="w-52 sm:w-auto pr-8 truncate appearance-none border border-white bg-maplexxon-blue focus:outline-none text-white rounded-xl py-2 px-3 text-xs lg:text-sm overflow-clip"
                            onChange={handleSelectProduct}
                        >
                            {products.filter(product => !product.beta).map((product, i) => (
                                <option className="w-min" key={i} value={i}>{product.name}</option>
                            ))}
                        </select>
                        <KeyboardArrowDownIcon className="absolute right-0 mr-3 w-3 fill-white" />
                    </div>
                    <div className='flex gap-2 items-center w-full mb-4'>
                        <div className='flex gap-2 items-center maplexxon-animation-translate-start'>
                            <ToggleButton
                                name="annually"
                                checked={annually}
                                onChange={(e) => setAnnually(e.target.checked)}
                            />
                            <p className='text-xs lg:text-sm uppercase text-white font-bold'>Annual Plan</p>
                        </div>
                        <Tooltip
                            text={
                                <p className='font-semibold text-[10px] sm:text-xs text-black'>
                                    by paying 12 months (annual package) upfront, once a year
                                </p>
                            }
                        />
                    </div>
                    <div className={`grid grid-cols-1 ${selectedPricings?.length === 3 ? "md:grid-cols-3" : "md:grid-cols-2"} gap-4 md:gap-8`}>
                        {selectedPricings?.map((pricing, i) => {
                            return (
                                <div>
                                    {pricing?.mostPopular && (
                                        <div className='px-8 sm:-mt-3'>
                                            <p className=' bg-maplexxon-mint text-white font-bold w-fit ml-auto py-1 px-2 rounded-t-lg uppercase text-xs sm:text-sm'>
                                                Most popular
                                            </p>
                                        </div>
                                    )}
                                    <div key={i} className={`bg-white p-4 md:py-8 md:px-6 rounded-xl drop-shadow-xl w-full ${pricing?.mostPopular ? "ring-4 ring-maplexxon-mint" : "sm:mt-4"}`}>
                                        <p className='text-lg lg:text-2xl font-semibold'>{pricing?.name}</p>
                                        {/* <p className='text-xs lg:text-sm mb-4'>{pricing?.description}</p> */}
                                        <div className='text-xs lg:text-sm mb-4 text-maplexxon-grey-2'>
                                            <p className='text-xs lg:text-sm font-bold uppercase'>Core pricing</p>
                                            <p className='text-xs lg:text-sm'>Rp {new Intl.NumberFormat().format((pricing?.corePrice) || 0)}</p>
                                        </div>
                                        <p className='text-lg lg:text-xl text-maplexxon-blue mt-4'>
                                            <span className='font-bold'>Rp {new Intl.NumberFormat().format((annually ? pricing?.annualPrice : pricing?.monthlyPrice) || 0)}&nbsp;</span>
                                            per {annually ? "year" : 'month'}
                                        </p>
                                        <p className='text-xs lg:text-sm text-maplexxon-grey-2 mb-2'>{pricing.priceDescriptionText}</p>
                                        {annually && (
                                            <p className='text-xs lg:text-sm text-maplexxon-grey-2'><span className='line-through text-maplexxon-grey-4'>Rp {new Intl.NumberFormat().format((pricing?.monthlyPrice) || 0)}</span>&nbsp;<span className='font-medium'>Rp {new Intl.NumberFormat().format((pricing?.discountedMonthlyPrice) || 0)} per month</span></p>
                                        )}
                                        <div className='text-center mt-6'>
                                            <button onClick={() => setRequestDemoModalOpen(true)} className='px-4 py-2 text-white rounded-md bg-maplexxon-blue mx-auto mb-10'>Get started</button>
                                        </div>
                                        <ul className='flex flex-col gap-2'>
                                            {pricing?.features?.map((feature, i) => (
                                                <li key={i} className='flex items-center gap-2 mb-2'>
                                                    <div>
                                                        <CheckIcon />
                                                    </div>
                                                    <p className='text-xs lg:text-sm text-maplexxon-grey-2'>{feature.text}{feature.value ? ` (${feature.value})` : ""}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className='bg-white'>
                <div className='flex flex-col max-w-main pt-4 pb-8 lg:py-16'>
                    <p className='text-lg lg:text-2xl mb-8 font-bold maplexxon-animation-translate-start'>Compare plans and features</p>
                    <div className='flex-1 overflow-x-auto mb-8 pb-4'>
                        <table className='w-full border-spacing-0 border-separate table-auto rounded-xl border border-maplexxon-blue'>
                            <thead>
                                <tr className='rounded-t-xl'>
                                    <th className='bg-maplexxon-grey-5 font-medium rounded-tl-xl border-r border-r-maplexxon-blue text-sm lg:text-lg text-left pt-3 pb-1 px-2 md:pt-6 md:pb-2 md:pl-4'>Features</th>
                                    {selectedPricings.map((pricing, i) => (
                                        <th key={i} className={`bg-maplexxon-grey-5 font-semibold text-maplexxon-blue ${i === selectedPricings.length - 1 ? "rounded-tr-xl" : "border-r border-r-maplexxon-blue"} text-sm lg:text-lg text-left pt-3 pb-1 px-2 md:pt-6 md:pb-2 md:pl-4`}>
                                            {pricing.name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {features?.map((item, index) => (
                                    <tr key={index}>
                                        <td className={`text-xs lg:text-sm py-2 px-2 md:py-4 md:pl-4 border-r border-r-maplexxon-blue ${index === features.length - 1 ? "pb-4 md:pb-8" : index === 0 ? "pt-4 md:pt-8" : ""}`}>{item.text}</td>
                                        {selectedPricings.map((pricing, i) => {
                                            const feature = pricing?.features?.find(feature => feature?.text === item?.text)
                                            const value = feature ? feature?.value ? feature?.value : "Yes" : "Not Included"
                                            return (
                                                <td className={`text-xs lg:text-sm py-2 px-2 md:py-4 md:pl-4 ${i === selectedPricings.length - 1 ? "" : "border-r border-r-maplexxon-blue"} ${index === features.length - 1 ? "pb-4 md:pb-8" : index === 0 ? "pt-4 md:pt-8" : ""}`}>{value}</td>
                                            )
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12'>
                        {selectedPricings.map((pricing, i) => (
                            <div key={i}>
                                <div key={i} className='border border-maplexxon-blue bg-maplexxon-grey-3 p-4 md:p-6 rounded-xl mb-2 md:mb-4'>
                                    <p className='text-base lg:text-xl mb-2'>{pricing.name}</p>
                                    <div className='text-xs lg:text-sm mb-4 text-maplexxon-grey-2'>
                                        <p className='text-xs lg:text-sm font-bold uppercase'>Core pricing</p>
                                        <p className='text-xs lg:text-sm'>Rp {new Intl.NumberFormat().format((pricing?.corePrice) || 0)}</p>
                                    </div>
                                    <p className='text-lg lg:text-xl text-maplexxon-blue mt-4'>
                                        <span className='font-bold'>Rp {new Intl.NumberFormat().format((annually ? pricing?.annualPrice : pricing?.monthlyPrice) || 0)}&nbsp;</span>
                                        per {annually ? "year" : 'month'}
                                    </p>
                                </div>
                                <button onClick={() => setRequestDemoModalOpen(true)} className='text-base lg:text-xl font-medium px-4 py-2 text-white rounded-md bg-maplexxon-blue mr-auto'>Get started</button>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className='bg-maplexxon-blue-light'>
                <div className='flex flex-col max-w-main pt-4 pb-8 lg:py-16'>
                    <p className='text-lg lg:text-2xl font-bold mb-2 maplexxon-animation-translate-start'>Recommended Add Ons</p>
                    <p className='text-sm lg:text-lg mb-8 maplexxon-animation-translate-start'>
                        Personalize and enhance your plan with these additional features.
                    </p>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-8 max-w-3xl'>
                        {addOns.map((item, i) => (
                            <div key={item.id} className='p-2 sm:p-4 border border-maplexxon-blue rounded-xl space-y-2 sm:space-y-4 maplexxon-animation-translate-start'>
                                <div className='flex items-start justify-between gap-2 cursor-pointer select-none' onClick={() => handleToggleAddOns(i)} >
                                    <p className='text-base lg:text-xl font-medium'>{item.name}</p>
                                    <button className={`${addOnsOpen.includes(i) ? "rotate-180" : "rotate-0"} transition-all`} >
                                        <KeyboardArrowDownIcon className="h-6 w-3 fill-maplexxon-grey-2" />
                                    </button>
                                </div>
                                <p className='text-xs lg:text-sm'>{item.description}</p>
                                {addOnsOpen.includes(i) && (
                                    <p className='pt-2 md:pt-4 text-xs lg:text-sm border-t border-maplexxon-grey-2'>
                                        {item.description2}
                                    </p>
                                )}
                                {item.multiPrice ? (
                                    <div>
                                        <p className='text-lg lg:text-xl text-maplexxon-blue'>
                                            Start from
                                        </p>
                                        <div className='flex items-center gap-2 sm:gap-4'>
                                            {item?.price?.map((p, i) => (
                                                <>
                                                    <div>
                                                        <p className='text-lg lg:text-xl text-maplexxon-blue'>
                                                            <span className='font-bold'>Rp {new Intl.NumberFormat().format(p || 0)}</span>
                                                        </p>
                                                        <p className='text-xs lg:text-sm text-maplexxon-blue lowercase'>{item.type[i]}</p>
                                                    </div>
                                                    {i !== item.price.length - 1 && (
                                                        <div className='text-sm lg:text-base text-maplexxon-blue'>
                                                            +
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p className='text-lg lg:text-xl text-maplexxon-blue'>Start from <span className='font-bold'>Rp {new Intl.NumberFormat().format(item?.price || 0)}</span></p>
                                        <p className='text-xs lg:text-sm text-maplexxon-blue'>{item.type}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* <section section className='bg-maplexxon-grey-light'>
                <div className='flex flex-col max-w-main pt-4 pb-8 lg:py-16'>
                    <div className='md:w-1/2 mb-12 md:mb-24'>
                        <p className='text-maplexxon-purple font-bold text-xl lg:text-3xl mb-2 maplexxon-animation-translate-start'>Used by the world's most amazing business</p>
                        <p className='text-base lg:text-xl maplexxon-animation-translate-start'>Lorem ipsum dolor sit amet consectetur. Vitae lacus quis pharetra faucibus a enim vel risus. </p>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 mb-12 md:mb-24'>
                        {reviews.map((review, i) => (
                            <div key={i} className='maplexxon-animation-translate-start'>
                                <p className='text-base lg:text-xl text-maplexxon-grey-2 font-medium mb-2 md:mb-8'>"{review.text}"</p>
                                <p className='text-base lg:text-xl font-medium mb-2'>{review.name}, {review.company}</p>
                                <p className='text-xs lg:text-sm text-maplexxon-grey-4'>{review.role}</p>
                            </div>
                        ))}
                    </div>
                    <div>
                        <button className='flex gap-4 items-center maplexxon-animation-scale-start'>
                            <p className='text-xs lg:text-sm font-bold'>Read all customer reviews</p>
                            <ArrowRightIcon className="w-4 h-4 fill-black" />
                        </button>
                    </div>
                </div>
            </section> */}

            <ContactWhatsapp />
            <FAQ />
        </div>
    )
}

export default Pricing