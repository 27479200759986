import React from 'react'

function HomeIcon({
    className,
    width = "60",
    height = "57",
    fill = "black"
}) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 60 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 29.0405L28.1146 3.92306C28.7067 3.33198 29.5092 3 30.3459 3C31.1825 3 31.985 3.33198 32.5771 3.92306L57.6945 29.0405M9.31091 22.7296V51.1286C9.31091 52.8705 10.7245 54.2841 12.4664 54.2841H24.0364V40.6105C24.0364 38.8687 25.45 37.455 27.1918 37.455H33.5027C35.2445 37.455 36.6582 38.8687 36.6582 40.6105V54.2841H48.2282C49.97 54.2841 51.3836 52.8705 51.3836 51.1286V22.7296M19.8291 54.2841H42.9691"
                stroke={fill}
                strokeWidth="4.20727"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default HomeIcon