import React from 'react'
import securityImg from 'assets/images/security.png'
import { security } from 'utils/constants/security'
import ContactWhatsapp from 'components/ContactWhatsapp/ContactWhatsapp'

function Security() {
    return (
        <div>
            <section className='bg-maplexxon-grey-3'>
                <div className='max-w-main py-8 sm:py-16 flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-8'>
                    <div className='w-full sm:w-1/2 space-y-4 sm:space-y-8'>
                        <h1 className='text-3xl lg:text-5xl leading-tight lg:leading-tight font-bold text-maplexxon-blue maplexxon-animation-translate-start'>
                            We are committed to your safety and security
                        </h1>
                        <p className='text-base lg:text-xl maplexxon-animation-translate-start'>
                            Maplexxon is commited to keeping your data safe and secure.
                            We provide solutions that are efficient, scalable and flexible.
                        </p>
                    </div>
                    <div className='w-full sm:w-1/2 maplexxon-animation-scale-start'>
                        <img src={securityImg} alt="security" />
                    </div>
                </div>
            </section>

            <section>
                <div className='max-w-main py-8 sm:py-16 space-y-8 sm:space-y-16'>
                    <div className='grid grid-cols-1 sm:grid-cols-3 gap-x-4 gap-y-6 sm:gap-x-8 sm:gap-y-12'>
                        {security.map((item, i) => {
                            const Icon = item.icon
                            return (
                                <div key={i} className='space-y-2 sm:space-y-4 maplexxon-animation-translate-start flex flex-col items-center sm:items-start'>
                                    <Icon className='w-12 sm:w-14 h-12 sm:h-14 fill-black' />
                                    <div className='flex flex-col items-center sm:items-start text-center sm:text-left space-y-1 sm:space-y-2'>
                                        <p className='font-bold text-base lg:text-xl'>{item.title}</p>
                                        <p className='font-medium text-sm lg:text-base'>{item.description}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <p className='font-bold text-base lg:text-xl leading-relaxed lg:leading-relaxed text-center max-w-3xl mx-auto maplexxon-animation-translate-start'>
                        We are committed to continuous improvement of our data residency practices, incorporating the latest advancements in data security and privacy to better protect your data. Also, we conduct regular security audits and compliance checks to ensure that our data residency practices align with the latest legal requirements and industry best practices.
                    </p>
                </div>
            </section>
            <ContactWhatsapp />
        </div>
    )
}

export default Security