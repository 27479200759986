import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowDownIcon'
import React from 'react'

function SelectInput({
    name,
    className,
    options,
    disabled,
    onChange,
    onBlur,
    value,
}) {
    return (
        <div className={`relative flex items-center overflow`}>
            <select
                id={name}
                name={name}
                className={`text-sm sm:text-base truncate appearance-none w-full border border-app-light-grey-2 rounded-lg bg-white text-black pl-3 pr-5 sm:pr-7 py-2 ${value ? "" : "text-black/40"} ${className}`}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
            >
                {options?.map((option, i) => (
                    <option value={option?.value} key={i} selected={option?.value === value}>{option?.label || "Select one"}</option>
                ))}
            </select>
            <KeyboardArrowDownIcon
                className="absolute right-0 mr-3 fill-black h-2 sm:h-3 w-2 sm:w-3"
            />
        </div>
    )
}

export default SelectInput