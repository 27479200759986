import React from 'react'

import integrationsMain from 'assets/images/integrations/integrations-main.png'
import wantToIntegrate from 'assets/images/integrations/want-to-integrate.png'

import { integrations } from 'utils/constants'

function Integrations() {
    return (
        <div>
            <section className='bg-maplexxon-grey'>
                <div className='max-w-main py-4 lg:py-8 grid grid-cols-1 sm:grid-cols-2 items-center'>
                    <h1 className='max-w-lg text-center sm:text-left text-xl lg:text-3xl leading-normal font-bold text-maplexxon-blue maplexxon-animation-translate-start'>
                        Experience the powerful software with your favorite apps all in one.
                    </h1>
                    <img src={integrationsMain} alt="integration-main" className='px-6 sm:px-4 maplexxon-animation-scale-start' />
                </div>
            </section>

            <section>
                <div className='max-w-main py-8 lg:py-16 flex flex-col items-center'>
                    <p className='text-center uppercase text-base lg:text-xl tracking-widest mb-10 max-w-xs md:max-w-lg'>Maplexxon provides integration with</p>
                    <div className='max-w-4xl grid grid-cols-1 md:grid-cols-2 justify-items-center gap-8'>
                        {integrations.map((value, i) => {
                            return (
                                <div key={i} className='flex flex-col items-center md:items-start px-4 py-6 md:px-10 md:py-12 bg-maplexxon-blue-light rounded-2xl maplexxon-animation-scale-start max-w-sm'>
                                    <img src={value.image} alt={value.title} className='h-16 w-48 object-contain object-center md:object-left mb-4' />
                                    <p className='text-base lg:text-xl font-bold mb-4 text-center md:text-left'>{value.title}</p>
                                    <p className='text-xs lg:text-sm text-center md:text-left'>{value.description}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className='bg-maplexxon-yellow-light'>
                <div className='max-w-main py-8 lg:py-16 flex flex-col sm:flex-row items-center gap-4 sm:gap-8'>
                    <img className='sm:w-1/2' src={wantToIntegrate} alt="want-to-integrate" />
                    <div className='sm:w-1/2 space-y-4 text-center sm:text-left'>
                        <p className='text-xl text-maplexxon-blue lg:text-3xl font-bold maplexxon-animation-translate-start'>
                            Want to integrate with other third party apps?
                        </p>
                        <p className='text-base lg:text-xl maplexxon-animation-translate-start'>
                            Consult with our support team and register your interest to access our private API.
                        </p>
                        <button onClick={() => window.open(`https://wa.me/+6281385898240`, "_blank")} className='bg-maplexxon-blue text-white px-3 sm:px-4 py-2 rounded-lg maplexxon-animation-translate-start text-sm: sm:text-base'>Chat with us</button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Integrations