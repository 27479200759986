export const privacy_policy = [
    {
        description: (
            <>
                <p>
                    This privacy policy (“Privacy Policy”) applies to Maplexxon, and all its affiliated companies, including Cloudxier and Institute for Sustainability and Agility (ISA) (hereafter referred to as “PT Cendekia Edukasi Citra Tritunggal”, “we”, “us” or “our”). We, at Maplexxon, know you (“you” or the “User”) care about how your personal information is used and shared - and we take your privacy seriously.
                </p>
                <p>
                    This Privacy Policy applies to all of your activities on our websites, services, web (<a className="text-maplexxon-blue" href="https://www.maplexxon.com">www.maplexxon.com</a>). This Privacy Policy describes and governs our practices regarding the collection, processing and usage of information we collect from Users (“Personal Data”). Data we collect from Users (“Personal Data”), is used, disclosed and protected according to this Privacy Policy. This Privacy Policy applies to Organizers and Users of our Services alike and contains provisions pertaining to each type.
                </p>
                <p>This policy applies to information we collect:</p>
                <ul className="list-disc">
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">In email, text, direct message, and other electronic messages between you and the services.</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Through mobile and desktop applications you access or download from this Service, which provides dedicated non-browser-based interaction between you and this Service.</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">When you interact with our advertising and applications on third-party Services and services, if those applications or advertising include links to this policy.</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">When you use our Services.</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">When you enter information into any of our website or applications.</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Service.</li>
                </ul>
            </>
        )
    },
    {
        title: "Maplexxon Privacy practices",
        description: (
            <>
                <p>
                    Here are the privacy practices of Maplexxon, which includes:
                </p>
                <ul className="list-disc">
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Maplexxon stores documents and images, where they are encrypted in cloud storage</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Maplexxon does not store or view user audio or video, which are encrypted in transit</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Maplexxon does not store any chat messages on our server. Chat messages are cached in your browser settings or your desktop system</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Maplexxon stores user email addresses, the date and time that a user created an account to access the Platform, and the last time a user logged in to the Platform</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">When you fill in the feedback or contact form on the Platform, we store your message, along with your name, email addresses or any other information only used to respond your message.</li>
                </ul>

            </>
        )
    },
    {
        title: "Data We Collect",
        description: (
            <>
                <p>In connection with your use of the Site and Services, we may receive various types of information related to you, as well as information you provide us about others.</p>
                <p>To the extent data is associated with an identified or identifiable natural person and is protected as personal information (or substantially equivalent terms) under applicable data protection laws, it is referred to in this Privacy Policy as “Personal Data.”  You acknowledge and agree we may collect, process, store, access, and disclose Personal Data disclosed by you to facilitate the provision of the Site and Services and related support for the Services in the manner described in this Privacy Policy.</p>
                <p>The personal data we collect, includes:</p>
                <ol className="list-[lower-alpha]">
                    <li className="list-item ml-4 sm:ml-6 pl-1 sm:pl-2">IP address</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Cookies ID (identification) tags</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Email address</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">First name, last name</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Phone number</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Gender</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Job/occupation</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Company name and size of the company</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Any personal data you provide when you fill in contact form/feedback form/when you request for a demo.</li>
                </ol>
                <p>If you use the Services to make payments of any kind, or to set up the capability to make payments, you may provide us (or our third-party payment) additional Personal Data including financial data (such as your credit card data or other applicable payment method, billing address, billing telephone number, or date of birth).</p>
                <p>We store very little, if any, customer financial data and such financial data is typically stored by our third-party payment processors, but we may use this data under certain circumstances to create and to provide support related to your account and to communicate with you relating to customer support inquiries as well as other communications related to the Services.</p>
                <p>We encourage you to review the privacy policies of any third-party payment processors used in connection with and to contact them directly if you have questions relating to your data, including Personal Data, as it relates to such payments. We currently use Midtrans to process debit and credit card payments, you may refer to their policies in this link <a className="text-maplexxon-blue" href="https://midtrans.com/privacypolicy">https://midtrans.com/privacypolicy</a></p>
            </>
        )
    },
    {
        title: "How We Store Data",
        description: (
            <>
                <p>IP addresses and cookies ID tags are logged automatically and kept in the relevant log files.</p>
            </>
        )
    },
    {
        title: "How We Use Data",
        description: (
            <>
                <p>We use IP addresses of our website's visitors for security reasons, particularly to identify malicious site visits and hacking attempts, to protect the website's data and traffic and make it safer for anyone visiting and using the website. Thus, we collect and use IP addresses of our website's visitors based on legitimate interest.</p>
                <p>We will not disclose IP addresses of the website's visitors to any third party except where required to do so by law.</p>
                <p>We use cookies ID tags to recognize you, keep track of your interaction with the site, remember your preferences and make all site's functions available for you. Since we may use third-party service providers to track and report websites traffic, those third-party service providers may place their own cookies on your computer and use the relevant cookies ID tags to measure and analyze information of your visits.</p>
                <p>We collect and use cookies ID tags based on your acceptance of the website's cookies. For more information about how we use cookies, how you can accept, manage or withdraw you consent for use of cookies please check our Cookie Notice.</p>
                <p>Specifically, we only use personal data to:</p>
                <ul className="list-disc">
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Provide, maintain and improve our site and services</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Monitor the usage of our site and services</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Detect, prevent, and address technical issues in connection with our site and services</li>
                </ul>
            </>
        )
    },
    {
        title: "Data Security",
        description: (
            <>
                <p>The security of your data is important to us, and we use industry standard methods for data encryption and key management to protect data transmitted over public networks and when stored on our systems.</p>
                <p>While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security, as no method of transmission over the internet or method of electronic storage is 100% secure. But, we have a security team dedicated to keeping personal information safe, we maintain administrative, technical and physical safeguards that are intended to appropriately protect against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse and any other unlawful form of processing, of the personal information in our possession.</p>
            </>
        )
    },
    {
        title: "Data Transfers",
        description: (
            <>
                <p>Personal information that you submit through the Services may be transferred to countries other than where you live, such as, for example, to our servers in Singapore. We also store personal information locally on the devices you use to access the Services.</p>
                <p>Except for the purposes described above, we will not transfer your Personal Data to any company, organization or individual, except in the following circumstances:</p>
                <ol className="list-decimal">
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">we may transfer your Personal Data to other parties with your explicit consent; or</li>
                    <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">in case of any acquisition, merger, insolvency or liquidation of Maplexxon, if the transfer of Personal Data is involved, we will require the new company, organization or individual in possession of your Personal Data to continue to be bound by this Privacy Policy, or we will require such company, organization or individual to obtain your authorization and consent for any use of your Personal Data.</li>
                </ol>
            </>
        )
    },
    {
        title: "Security",
        description: (
            <>
                <p>We take various precautions to protect your Personal Data from loss, misappropriation and misuse, and from being accessed, disclosed, modified or destroyed without permission. In order to ensure the safety of your Personal Data, we have established strict information security provisions and procedures to protect your Personal Data.</p>
                <p>In the event of any Personal Data security breach, we will, in accordance with the requirements of applicable laws and in all cases within 30 days, notify you of the basic information and possible consequences of such security breach, actions we have taken or will take in response to the breach, suggestions on how you may prevent and reduce risks on your own, and actions we will take on your behalf.</p>
            </>
        )
    },
    {
        title: "Request to delete your personal data",
        description: (
            <>
                <p>If you would like to request Maplexxon to delete your personal data, you may contact us directly through <a className="text-maplexxon-blue" href="https://wa.me/+6281385898240" target="_blank" rel="noreferrer">+62 813 8589 8240</a> to submit your request. Maplexxon will respond within a reasonable time frame in accordance with applicable laws and regulations.</p>
            </>
        )
    },
]