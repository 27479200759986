import React, { useEffect, useRef, useState } from 'react'

import ArrowRightIcon from 'assets/icons/ArrowRightIcon'
import mainart from 'assets/images/main-art.png'
import sample from "assets/videos/sample.mp4"

import {
    companies,
    showcases,
    sliding_banner,
    our_clients_banner
} from 'utils/constants'
import { products } from 'utils/constants/product'
import FAQ from 'components/FAQ/FAQ'
import { useRequestDemoModal } from 'context/RequestDemoModalContext'
import ContactWhatsapp from 'components/ContactWhatsapp/ContactWhatsapp'
import itSupportBanner from 'assets/images/banners/it_support_banner.png'
import CircleIcon from 'assets/icons/CircleIcon'

function Main() {
    const companiesRef = useRef(null);
    const slideContainerRef = useRef(null)
    const slidesRef = useRef([])
    const { setRequestDemoModalOpen } = useRequestDemoModal();
    const [currBannerIndex, setCurrBannerIndex] = useState(0)

    useEffect(() => {
        if (companiesRef?.current) {
            companiesRef.current.scrollBy(0, 1)
        }
    }, [])

    useEffect(() => {
        const slideContainer = slideContainerRef?.current
        const slides = slidesRef?.current
        const slideWidth = slides?.[0].clientWidth;
        const interval = 3000;
        let currentIndex = 0;

        function nextSlide() {
            currentIndex = (currentIndex + 1) % slides.length;
            setCurrBannerIndex(currentIndex)
            updateSlide();
        }

        function updateSlide() {
            const translateX = -currentIndex * slideWidth;
            slideContainer.style.transform = `translateX(${translateX}px)`;
        }

        const slideInterval = setInterval(nextSlide, interval);
        return () => clearInterval(slideInterval)
    }, [])

    return (
        <main className="">
            <section className='bg-maplexxon-blue-light'>
                <div className='flex flex-col-reverse md:flex-row items-center lg:items-left max-w-main pt-4 pb-8 lg:py-12'>
                    <div className='lg:w-7/12'>
                        <h1 className='text-3xl lg:text-5xl leading-tight lg:leading-tight font-bold text-maplexxon-blue mb-4 lg:mb-12 maplexxon-animation-translate-start'>Ready-made solutions to support your Business</h1>
                        <p className='text-base lg:text-xl mb-4 lg:mb-12 maplexxon-animation-translate-start'>
                            Connect and integrate easily with our tech solutions.
                            <br />
                            With Maplexxon, we're building platform and products to make your work life easier.
                        </p>
                        <button
                            className='w-full md:w-auto text-base lg:text-xl text-white rounded-md bg-maplexxon-blue px-4 py-2 lg:px-6 lg:py-3 maplexxon-animation-translate-start'
                            onClick={() => setRequestDemoModalOpen(true)}
                        >
                            Request for Demo
                        </button>
                    </div>
                    <div className='lg:w-5/12'>
                        <img className="maplexxon-animation-scale-start" src={mainart} alt="outline-man-web-designer-creating-landing-page" />
                    </div>
                </div>
            </section>

            <section className='py-8 lg:py-12'>
                <div className='max-w-main flex flex-col items-center'>
                    <div className='cursor-pointer w-full' onClick={() => window.location.assign("/pricing")}>
                        <img className='w-full rounded-md sm:rounded-2xl maplexxon-animation-scale-start' src={itSupportBanner} alt="it_support_banner" />
                    </div>
                </div>
            </section>

            <section className='py-8 lg:py-12 bg-maplexxon-grey-light'>
                <div className='max-w-main'>
                    <p className='text-center uppercase text-base lg:text-xl tracking-widest mb-4 lg:mb-10'>Our solutions</p>
                    <p className='text-xl lg:text-3xl leading-normal font-bold text-center mb-8 lg:mb-20 maplexxon-animation-translate-start'>Offering multiple solutions <br />to simplify processes and flows.</p>
                    <div className='flex flex-wrap gap-8 lg:gap-12 justify-between'>
                        {products.map((product, i) => {
                            const Icon = product.icon;
                            return (
                                <div key={i} className='flex flex-col lg:flex-row items-center lg:items-start w-product gap-2 lg:gap-6'>
                                    <div>
                                        <div className='h-16 w-16 lg:w-20 lg:h-20 bg-maplexxon-grey rounded-full flex items-center justify-center maplexxon-animation-scale-start'>
                                            <Icon className="h-8 w-8 lg:h-12 lg:w-12" />
                                        </div>
                                    </div>
                                    <div className='flex flex-1 flex-wrap flex-col gap-2 lg:gap-4 justify-between items-center lg:items-start'>
                                        <div className='flex flex-wrap flex-col gap-2 lg:gap-4 items-center lg:items-start'>
                                            <div className='flex flex-col items-center gap-2'>
                                                <p className="text-lg lg:text-2xl text-center sm:text-left text-maplexxon-blue font-bold maplexxon-animation-translate-start">
                                                    {product.beta && (
                                                        <div className='hidden sm:block float-right whitespace-nowrap uppercase bg-maplexxon-purple w-min px-2 py-1 md:px-3 md:py-1 text-[10px] sm:text-xs rounded-lg text-white maplexxon-animation-translate-start'>Coming Soon</div>
                                                    )}
                                                    {product.name}
                                                </p>
                                                {product.beta && (
                                                    <div className='block sm:hidden float-right whitespace-nowrap uppercase bg-maplexxon-purple w-min px-2 py-1 md:px-3 md:py-1 text-[10px] sm:text-xs rounded-lg text-white maplexxon-animation-translate-start'>Coming Soon</div>
                                                )}
                                            </div>
                                            <p className='text-sm lg:text-base text-center lg:text-left maplexxon-animation-translate-start'>{product.description}</p>
                                        </div>
                                        {!product.beta && (
                                            <a className='flex items-center gap-2 font-bold maplexxon-animation-translate-start' href={`/product/${product.slug}`}>
                                                <p className='text-sm lg:text-base'>Explore Now</p>
                                                <ArrowRightIcon className="w-3 sm:w-4 h-3 sm:h-4 fill-black" />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className='bg-maplexxon-grey-light'>
                <div className='py-8 lg:py-12 w-full max-w-4xl mx-auto overflow-hidden maplexxon-animation-scale-start'>
                    <div ref={slideContainerRef} className='flex transition-all duration-500'>
                        {sliding_banner.map((item, i) => (
                            <div onClick={() => window.location.assign(item?.href)} ref={(el) => slidesRef.current[i] = el} className='flex-1 max-h-96 min-w-full cursor-pointer px-6 md:px-8 xl:px-0'>
                                <img src={item?.img} alt={item?.name} className='w-full' />
                            </div>
                        ))}
                    </div>
                    <div className='flex justify-center space-x-1 sm:space-x-2 py-2 sm:py-4'>
                        {sliding_banner.map((item, i) => (
                            <div><CircleIcon className={`w-1 sm:w-2 h-1 sm:h-2 ${i === currBannerIndex ? "fill-black" : "fill-none"}`} /></div>
                        ))}
                    </div>
                </div>
            </section>

            <section className='py-8 lg:py-12 bg-white'>
                <div className='max-w-main space-y-12'>
                    <div className='space-y-2 md:space-y-4'>
                        <h3 className='text-xl lg:text-3xl text-center font-bold maplexxon-animation-translate-start'>Move your business forward with Maplexxon</h3>
                        <p className='text-sm lg:text-lg text-center maplexxon-animation-translate-start max-w-2xl mx-auto'>Software solutions that empower you to work and run your business efficiently, driving growth for the better future. </p>
                    </div>
                    <div className='flex flex-col gap-2 lg:gap-4'>
                        {showcases.map((product, i) => {
                            return (
                                <div key={i} className={`flex ${i % 2 === 0 ? "flex-col md:flex-row" : "flex-col md:flex-row-reverse"} gap-1 lg:gap-2 justify-center items-center`}>
                                    <div className='mx-auto px-12 lg:px-0 md:flex-1'>
                                        <img className='object-contain maplexxon-animation-scale-start' src={product.img} alt={product.name} />
                                    </div>
                                    <div className='flex flex-col gap-4 lg:gap-8 justify-center md:flex-1'>
                                        <h3 className='text-xl lg:text-3xl text-center lg:text-left font-bold maplexxon-animation-translate-start'>{product.heading}</h3>
                                        <p className='text-base lg:text-xl text-center leading-snug lg:leading-snug lg:text-left maplexxon-animation-translate-start'>{product.description}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className='py-8 lg:py-12 bg-maplexxon-grey-light'>
                <div className='max-w-main space-y-12'>
                    <div className='space-y-2 md:space-y-4'>
                        <h3 className='text-xl lg:text-3xl text-center font-bold maplexxon-animation-translate-start'>Our Clients</h3>
                    </div>
                    <div className='flex flex-wrap justify-center'>
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-8 lg:gap-12 maplexxon-animation-translate-start">
                            {our_clients_banner.map((client, i) => {
                                return (
                                    <div>
                                        <img class="h-auto max-w-xs sm:max-w-full rounded-lg" src={client.img} alt={client.name} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <ContactWhatsapp />
            {/* <section className='py-8 lg:py-12 bg-maplexxon-grey-light'>
                <div className='max-w-main flex flex-col-reverse md:flex-row gap-4 md:gap-16 items-center'>
                    <div className='w-full lg:w-7/12'>
                        <video className='rounded-3xl maplexxon-animation-scale-start' src={sample} controls muted />
                    </div>
                    <div>
                        <p className='uppercase text-base lg:text-xl text-center lg:text-left tracking-widest mb-6 maplexxon-animation-translate-start'>OUR EXCELLENCE</p>
                        <p className='text-xl lg:text-3xl font-bold mb-2 lg:mb-12 maplexxon-animation-translate-start'>Award-winning solutions</p>
                        <p className='text-base lg:text-xl maplexxon-animation-translate-start'>Lorem ipsum dolor sit amet consectetur. Vitae lacus quis pharetra faucibus a enim vel risus. </p>
                    </div>
                </div>
            </section> */}
            <FAQ />
        </main>
    )
}

export default Main