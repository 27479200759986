import React, { useCallback, useEffect, useRef, useState } from 'react';

import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowDownIcon';
import MenuIcon from 'assets/icons/MenuIcon';
import maplexxon_logo from "assets/images/maplexxon_logo_black.png"

import { navLinks } from 'utils/constants';

function Navbar({
    scrolled,
    onClickMenuButton,
    onClickDemo
}) {
    const dropdownRef = useRef(null)
    const buttonRef = useRef([])
    const [dropdownOpen, setDropdownOpen] = useState(null);

    const handleMouseDown = useCallback((e) => {
        if (dropdownOpen && !dropdownRef?.current?.contains(e.target) && !buttonRef.current?.[dropdownOpen]?.contains(e.target)) {
            setDropdownOpen(false)
        }
    }, [dropdownOpen, setDropdownOpen])

    useEffect(() => {
        window.addEventListener('mousedown', handleMouseDown)
        return () => {
            window.removeEventListener('mousedown', handleMouseDown)
        }
    }, [handleMouseDown])

    return (
        <nav className={`sticky top-0 ${scrolled ? "shadow-md bg-white" : "bg-maplexxon-blue-light"} transition-all duration-300 z-20`}>
            <div className='flex w-full justify-between lg:justify-center items-center max-w-navigation py-4 lg:py-6'>
                <a href="/" className='lg:w-48 xl:w-64 flex items-center'>
                    <img className='w-28 lg:w-40 h-auto object-contain' src={maplexxon_logo} alt="maplexxon" />
                </a>
                <ul className='hidden lg:flex flex-1 justify-center gap-6 xl:gap-8 items-center' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    {navLinks.map((item, i) => (
                        <li key={i}>
                            <a
                                ref={el => buttonRef.current[i] = el}
                                href={item?.path}
                                className={`flex items-center gap-2 cursor-pointer select-none hover:border-b hover:border-black hover:pb-1 hover:-mb-1 transition-all duration-200 ${window.location.pathname === item.path ? "border-b border-black pb-1 -mb-1" : "border-b border-transparent pb-1 -mb-1"}`}
                                onClick={() => setDropdownOpen(prev => prev === i ? null : i)}
                            >
                                {item.label}
                                {item.dropdownItems?.length > 0 && (
                                    <KeyboardArrowDownIcon className={`h-6 w-3 fill-maplexxon-grey-2 ${dropdownOpen === i ? "rotate-180" : "rotate-0"} transition-all`} />
                                )}
                            </a>
                        </li>
                    ))}
                </ul>
                <div className='hidden lg:flex lg:w-48 xl:w-64 gap-7 justify-end items-center'>
                    <button onClick={onClickDemo} className='bg-maplexxon-purple text-white px-4 py-2 rounded-md'>Get a demo</button>
                </div>
                <button className="inline lg:hidden" onClick={onClickMenuButton}>
                    <MenuIcon
                        width='20'
                        height='20'
                    />
                </button>
            </div>
            {navLinks?.[dropdownOpen]?.dropdownItems && (
                <div ref={dropdownRef} className={`hidden sm:block absolute ${scrolled ? "bg-white" : "bg-maplexxon-blue-light"} left-0 right-0 shadow-md border-t border-maplexxon-grey-4`}>
                    <div className='max-w-navigation py-4 lg:py-6 grid grid-cols-1 sm:grid-cols-3 gap-4'>
                        {navLinks?.[dropdownOpen]?.dropdownItems?.filter(item => !item.beta).map((item, i) => {
                            const Icon = item.icon
                            return (
                                <a key={i} href={`/product/${item.slug}`} className='text-xs md:text-sm select-none flex space-x-4 hover:opacity-50 transition-all'>
                                    <div>
                                        <div className='rounded-full bg-maplexxon-grey h-10 w-10 flex justify-center items-center'>
                                            <Icon className="h-6 w-6 fill-maplexxon-purple" />
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <p className='font-bold'>{item.name}</p>
                                        <p>{item.description}</p>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </div>
            )}
        </nav>
    )
}

export default Navbar