import React from 'react'

function PageNotFound() {
    return (
        <div>
            <section>
                <div className='max-w-main h-[calc(100vh-52px)] lg:h-[calc(100vh-88px)] py-16 lg:py-40 flex flex-col items-center text-center justify-center space-y-4 sm:space-y-8'>
                    <div className='space-y-1 sm:space-y-2'>
                        <h1 className='text-2xl lg:text-4xl leading-normal font-bold'>
                            404
                        </h1>
                        <p className='text-lg lg:text-xl leading-normal font-medium'>
                            Page Not Found
                        </p>
                    </div>
                    <button className='bg-maplexxon-grey-2 text-white font-semibold px-3 py-2 rounded-md text-base sm:text-lg' onClick={() => window.location.assign("/")}>
                        Back to home
                    </button>
                </div>
            </section>
        </div>
    )
}

export default PageNotFound