module.exports = {
  PORT: "4000",
  NODE_ENV: "production",

  SERVER_DEV_URL: "http://localhost:3123",
  SERVER_STAGING_URL: "https://echostg.maplexxon.com",
  SERVER_PROD_URL: "https://echo.maplexxon.com",

  CLIENT_DEV_HOST: "localhost",
  CLIENT_STAGING_HOST: "stg.maplexxon.com",
  CLIENT_PROD_HOST: "maplexxon.com",

  CLIENT_DEV_URL: "http://localhost:3000",
  CLIENT_STAGING_URL: "https://stg.maplexxon.com",
  CLIENT_PROD_URL: "https://maplexxon.com",

  MAIN_WEBSITE_TITLE: 'MAPLEXXON | ',
  SITE_NAME: 'Maplexxon'
}
