import React from 'react'

import Team from 'components/Team/Team'

function Leadership() {
    return (
        <Team />
    )
}

export default Leadership