import React from 'react'
import InfoIcon from 'assets/icons/InfoIcon'

function Tooltip({
    text,
}) {
    return (
        <div className='relative flex items-center flex-1'>
            <button className='peer'>
                <InfoIcon />
            </button>
            <div className='hidden peer-hover:block absolute bg-white p-2 sm:px-6 sm:py-4 rounded-md z-20 drop-shadow-lg top-0 left-0 right-0 sm:right-auto ml-6 transition-all'>
                {text}
            </div>
        </div>
    )
}

export default Tooltip