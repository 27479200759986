import DataResidencyIcon from "assets/icons/DataResidencyIcon";
import DataBackupIcon from "assets/icons/DataBackupIcon";
import VulnerabilityTestIcon from "assets/icons/VulnerabilityTestIcon";
import StorageEncryptionIcon from "assets/icons/StorageEncryptionIcon";
import ServerIcon from "assets/icons/ServerIcon";
import CustomerPaymentIcon from "assets/icons/CustomerPaymentIcon";

export const security = [
    {
        icon: DataResidencyIcon,
        title: "Data access control",
        description: "Access to your data will be strictly controlled, with role-based access policies and authentication mechanisms in place to ensure that only authorized personnel can access your information."
    },
    {
        icon: DataBackupIcon,
        title: "Data backups",
        description: "Maplexxon uses many techniques to increase reliable uptime (>99,9%), including the load balancing, task queues, rolling deployments. We create daily backups, encrypt and store it securely."
    },
    {
        icon: VulnerabilityTestIcon,
        title: "Vulnerability testing",
        description: "We conduct vulnerability testing that is evaluated by our development team in sync with the site release cycle."
    },
    {
        icon: StorageEncryptionIcon,
        title: "Data encryption",
        description: "We implement robust encryption for your data, it is encrypted both in transit and rest to prevent unauthorized access and ensure confidentiality."
    },
    {
        icon: ServerIcon,
        title: "Transparent data handling",
        description: "We are committed to providing you with clear and concise information about where your data is stored and processed. You will have full visibility into the geographic locations of our data centers."
    },
    {
        icon: CustomerPaymentIcon,
        title: "Customer payments",
        description: "Maplexxon integrates with credible payment gateway, which is compliant with PCI DSS and does not process, transmit, or store any payment card data."
    },
]