import React, { useState } from 'react'

import CloseIcon from 'assets/icons/CloseIcon'
import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowDownIcon'
import { navLinksMobile } from 'utils/constants'

function Menu({
    className,
    scrolled,
    onClose,
    onClickDemo
}) {
    const [dropdownOpen, setDropdownOpen] = useState(null)

    return (
        <div className={`${className} fixed top-0 bottom-0 left-0 right-0 flex flex-col lg:hidden ${scrolled ? "shadow-md bg-white" : "bg-maplexxon-blue-light"} gap-4 px-4 py-4 transition-all duration-300`}>
            <div className='flex justify-end mb-8'>
                <button onClick={onClose}>
                    <CloseIcon
                        className="h-5 w-5 fill-black"
                    />
                </button>
            </div>
            <ul className='flex flex-col justify-center gap-6 text-base lg:text-xl font-semibold'>
                {navLinksMobile.map((item, i) => (
                    <li key={i} onClick={item.path ? onClose : null}>
                        <a
                            href={item?.path}
                            className={`flex items-center gap-2 cursor-pointer select-none w-fit ${window.location.pathname === item.path ? "border-b border-black pb-1 -mb-1" : "border-b border-transparent pb-1 -mb-1"}`}
                            onClick={() => {
                                if (item?.isDemo) {
                                    onClickDemo();
                                    onClose();
                                }
                                setDropdownOpen(prev => prev === i ? null : i)
                            }}
                        >
                            {item.label}
                            {item.dropdownItems?.length > 0 && (
                                <KeyboardArrowDownIcon className={`h-6 w-3 fill-maplexxon-grey-2 ${dropdownOpen === i ? "rotate-180" : "rotate-0"} transition-all`} />
                            )}
                        </a>
                        {(dropdownOpen === i && navLinksMobile?.[i]?.dropdownItems) && (
                            <div className='mt-2 space-y-2'>
                                {navLinksMobile?.[dropdownOpen]?.dropdownItems?.filter(item => !item.beta).map((item, i) => {
                                    return (
                                        <a key={i} href={`/product/${item.slug}`} className='text-xs md:text-sm select-none flex space-x-4'>
                                            <p className='font-semibold text-maplexxon-purple'>{item.name}</p>
                                        </a>
                                    )
                                })}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Menu