import React, { useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { sendContactUsMail } from 'api'
import ReCAPTCHA from "react-google-recaptcha";
import WhatsappIcon from 'assets/icons/WhatsappIcon';
import CompanyProfileIcon from 'assets/icons/CompanyProfileIcon';

function ContactUs() {
    const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
    const [captchaError, setCaptchaError] = useState(false)

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    } = useFormik({
        initialValues: {
            full_name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            marketing: false,
        },
        validationSchema: Yup.object({
            full_name: Yup.string().required('Full name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            phone: Yup.number().required('Phone number is required'),
            subject: Yup.string().required('Subject is required'),
            message: Yup.string().required('Message is required'),
            marketing: Yup.boolean(),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            if (isCaptchaCorrect) {
                setSubmitting(true)
                await sendContactUsMail("contact-us", values.full_name, values.email, values.phone, values.subject, values.message, values?.marketing)
                setSubmitting(false)
                window.location.reload();
            } else {
                setCaptchaError(true)
            }
        },
    })

    const onChangeCaptcha = (value) => {
        setCaptchaError(false)
        if (value) {
            setCaptchaCorrect(true);
        } else {
            setCaptchaCorrect(false);
        }
    };

    return (
        <div>
            <div className='max-w-main py-8 lg:py-16 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8'>
                <div>
                    <p className='text-xl lg:text-3xl font-bold mb-6 lg:mb-12 text-center md:text-left text-maplexxon-purple maplexxon-animation-translate-start'>
                        Get in touch with us
                    </p>
                    <p className='text-base lg:text-xl text-center md:text-left mb-8 maplexxon-animation-translate-start'>
                        Have questions? We are here to help you.
                    </p>
                    <div>
                        <p className='text-sm lg:text-base mb-2 font-bold uppercase maplexxon-animation-translate-start'>Our Office</p>
                        <div className='text-xs lg:text-sm mb-4 maplexxon-animation-translate-start'>
                            <p>PT. Cendekia Edukasi Citra Tritunggal HQ</p>
                            <p>AKR Tower 16th Floor Unit 16A</p>
                            <p>Jl Panjang No. 5 Kebon Jeruk</p>
                            <p>West Jakarta, 11530</p>
                        </div>
                    </div>
                    <div>
                        <p className='text-sm lg:text-base mb-2 font-bold uppercase maplexxon-animation-translate-start'>Operating hours</p>
                        <p className='text-xs lg:text-sm mb-4 maplexxon-animation-translate-start'>
                            Monday to Friday 10:00 - 18:00
                        </p>
                    </div>
                    <div>
                        <p className='text-sm lg:text-base mb-2 font-bold uppercase maplexxon-animation-translate-start'>Contact us</p>
                        <p className='text-xs lg:text-sm mb-2 maplexxon-animation-translate-start space-x-2'>
                            <span>+62 813 8589 8240</span>
                            <button onClick={() => window.open(`https://wa.me/+6281385898240`, "_blank")}>
                                <WhatsappIcon className='h-3 w-3 sm:h-4 sm:w-4 fill-black' />
                            </button>
                        </p>
                        <p className='text-xs lg:text-sm mb-2 maplexxon-animation-translate-start space-x-2'>
                            <span>+6221 8064 7744</span>
                            <button disabled>
                                <CompanyProfileIcon fill='#000000' className='h-3 w-3 sm:h-4 sm:w-4 fill-black' />
                            </button>
                        </p>
                        <p className='text-xs lg:text-sm mb-4 maplexxon-animation-translate-start'>
                            sales@maplexxon.com
                        </p>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-2'>
                        <label htmlFor="full_name" className='text-xs font-bold uppercase'>Full name</label>
                        <input
                            id="full_name"
                            name="full_name"
                            className="text-left px-3 py-2 rounded-md border border-maplexxon-grey-2 text-xs text-maplexxon-blue-green"
                            placeholder="Enter your full name"
                            value={values.full_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete='off'
                        />
                        {errors?.full_name && touched?.full_name && (
                            <p className='text-xs text-red-500'>{errors?.full_name}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label htmlFor="email_address" className='text-xs font-bold uppercase'>Email address</label>
                        <input
                            id="email"
                            name="email"
                            className="text-left px-3 py-2 rounded-md border border-maplexxon-grey-2 text-xs text-maplexxon-blue-green"
                            placeholder="Enter your email address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete='off'
                        />
                        {errors?.email && touched?.email && (
                            <p className='text-xs text-red-500'>{errors?.email}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label htmlFor="phone_number" className='text-xs font-bold uppercase'>Phone number</label>
                        <input
                            id="phone"
                            name="phone"
                            type="number"
                            className="text-left px-3 py-2 rounded-md border border-maplexxon-grey-2 text-xs text-maplexxon-blue-green"
                            placeholder="Enter your phone number"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete='off'
                        />
                        {errors?.phone && touched?.phone && (
                            <p className='text-xs text-red-500'>{errors?.phone}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label htmlFor="subject" className='text-xs font-bold uppercase'>Subject</label>
                        <input
                            id="subject"
                            name="subject"
                            className="text-left px-3 py-2 rounded-md border border-maplexxon-grey-2 text-xs text-maplexxon-blue-green"
                            placeholder="Enter your subject"
                            value={values.subject}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors?.subject && touched?.subject && (
                            <p className='text-xs text-red-500'>{errors?.subject}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label htmlFor="your_message" className='text-xs font-bold uppercase'>Your message</label>
                        <textarea
                            id="message"
                            name="message"
                            className="text-left px-3 py-2 rounded-md border border-maplexxon-grey-2 text-xs text-maplexxon-blue-green"
                            placeholder="Enter your message here"
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={8}
                            autoComplete='off'
                        />
                        {errors?.message && touched?.message && (
                            <p className='text-xs text-red-500'>{errors?.message}</p>
                        )}
                    </div>
                    <div className={`w-min ${captchaError ? "border border-red-500" : ""}`}>
                        <ReCAPTCHA
                            sitekey="6LehV8goAAAAACpKWwgn14wFJ2tlxt9dtzDzSVfu"
                            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // use it for testing purpose only
                            onChange={onChangeCaptcha}
                        />
                    </div>
                    {captchaError && (
                        <p className='text-xs text-red-500'>
                            Please check the captcha box before you proceed
                        </p>
                    )}
                    <div className='space-y-4'>
                        <p className='text-xs lg:text-sm'>By registering, you confirm that you agree to the processing of your personal data by Maplexxon as described in the <a className="underline" href='/privacypolicy' target='_blank' rel='noreferrer'>Privacy Policy</a></p>
                        <div className='flex items-start gap-2'>
                            <input
                                type='checkbox'
                                id="marketing"
                                name="marketing"
                                value={values.marketing}
                                onChange={handleChange}
                            />
                            <label htmlFor="marketing" className='text-xs lg:text-sm'>
                                Yes, I would like to receive marketing communications about the Maplexxon - PT Cendekia Edukasi Citra Tritunggal subsidiary companies, products, services, and events. I can unsubscribe at any time.
                            </label>
                        </div>
                    </div>
                    <button
                        disabled={
                            isSubmitting ||
                            !values.email ||
                            values.email === '' ||
                            !values.full_name ||
                            values.full_name === '' ||
                            !values.phone ||
                            values.phone === '' ||
                            !values.subject ||
                            values.subject === '' ||
                            !values.message ||
                            values.message === '' ||
                            !values.marketing ||
                            !isCaptchaCorrect
                        }
                        className='flex-1 text-sm lg:text-base bg-maplexxon-blue text-white px-6 py-2 rounded-md disabled:bg-slate-300'
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div >
    )
}

export default ContactUs