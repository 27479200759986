import React from 'react'

function CheckIcon({
    className = "h-3 w-3 fill-maplexxon-grey-2"
}) {
    return (
        <svg className={className} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4554 1.15667C17.6768 0.997288 17.9413 0.946587 18.1934 1.0152C18.4455 1.08382 18.6655 1.26643 18.8074 1.52471L19.7974 3.32903C19.9267 3.56485 19.9821 3.84772 19.9537 4.12762C19.9253 4.40751 19.8151 4.66641 19.6424 4.85853L19.6394 4.86331L19.6254 4.87884L19.5684 4.94217L19.3434 5.19908C18.0983 6.64206 16.8911 8.13085 15.7234 9.66329C13.5264 12.5502 10.9174 16.3285 9.16136 19.9946C8.67136 21.0174 7.47436 21.2373 6.76436 20.3542L0.279362 12.3029C0.186428 12.1874 0.113862 12.0509 0.0659787 11.9013C0.0180956 11.7517 -0.00412753 11.5921 0.000630111 11.432C0.00538775 11.2719 0.0370291 11.1146 0.0936734 10.9695C0.150318 10.8243 0.230809 10.6942 0.330362 10.587L2.29036 8.47435C2.4626 8.28879 2.68346 8.18054 2.91514 8.16811C3.14682 8.15569 3.3749 8.23987 3.56036 8.40624L6.86936 11.3708C12.0374 5.28033 14.9694 2.94307 17.4554 1.15667Z" />
        </svg>
    )
}

export default CheckIcon