import React from 'react'
import { useParams } from 'react-router-dom'

import ArrowRightLongIcon from 'assets/icons/ArrowRightLongIcon';
import CheckCircleIcon from 'assets/icons/CheckCircleIcon';

import { products } from 'utils/constants/product'
import FAQ from 'components/FAQ/FAQ';
import { useRequestDemoModal } from 'context/RequestDemoModalContext';
import ContactWhatsapp from 'components/ContactWhatsapp/ContactWhatsapp';

function Product() {
    const { slug } = useParams();
    const pathname =
        window.location.pathname &&
        window.location.pathname.split('/')
    const params = pathname && pathname[pathname.length - 1]
    let productSlug = slug ? slug : params
    const { setRequestDemoModalOpen } = useRequestDemoModal();
    const product = products?.find(product => product?.slug === productSlug)

    return (
        <div>
            <section className='bg-maplexxon-grey-3'>
                <div className='max-w-main pt-4 pb-8 lg:py-16 flex flex-col-reverse md:flex-row items-center gap-4 md:gap-8'>
                    <div className="w-full">
                        <h1 className='text-3xl lg:text-5xl leading-tight lg:leading-tight font-bold text-maplexxon-blue mb-4 lg:mb-12 maplexxon-animation-translate-start'>{product.details.title}</h1>
                        <p className='text-base lg:text-xl mb-4 lg:mb-12 maplexxon-animation-translate-start'>
                            {product.details.subtitle}
                        </p>
                        <div className='flex gap-4 items-stretch md:items-center flex-col md:flex-row'>
                            <button className='text-base lg:text-xl text-white rounded-md bg-maplexxon-blue px-4 py-2 lg:px-6 lg:py-3 maplexxon-animation-translate-start' onClick={() => setRequestDemoModalOpen(true)}>
                                Request for Demo
                            </button>
                            <a href="#features" className='text-center text-base lg:text-xl text-maplexxon-blue rounded-md border border-maplexxon-blue px-4 py-2 lg:px-6 lg:py-3 maplexxon-animation-translate-start'>
                                See Features
                            </a>
                        </div>
                    </div>
                    <div className='w-full'>
                        <img className="maplexxon-animation-scale-start" src={product.details.img} alt="outline-man-web-designer-creating-landing-page" />
                    </div>
                </div>
            </section>

            {/* <section className='bg-white'>
                <div className='max-w-main py-8 lg:py-16'>
                    <p className='text-center uppercase text-base lg:text-xl tracking-widest mb-10'>Trusted by brands</p>
                    <div className='flex justify-start lg:justify-center gap-8 max-w-main overflow-x-auto'>
                        {product.details.brands.map((company, i) => (
                            <img className="object-contain maplexxon-animation-scale-start w-1/2" id={company.id} key={company.id} src={company.src} alt={company.name} />
                        ))}
                    </div>
                </div>
            </section> */}

            {product.details.body.map((section, i) => {
                return (
                    <section id={section.name} key={i} className={`${section.className}`}>
                        <div className='max-w-main py-8 lg:py-16'>
                            <div>
                                {section.name && (
                                    <p className='text-center uppercase text-base lg:text-xl tracking-widest mb-8 maplexxon-animation-translate-start'>{section.name}</p>
                                )}
                                {section.title && (
                                    <p className='text-center text-xl lg:text-3xl font-bold mb-8 max-w-screen-sm mx-auto maplexxon-animation-translate-start'>{section.title}</p>
                                )}
                                {section.description && (
                                    <p className='text-center text-base lg:text-xl max-w-screen-sm mx-auto text-maplexxon-blue maplexxon-animation-translate-start'>{section.description}</p>
                                )}
                            </div>
                            <div className={`${section.itemGroupClassName}`}>
                                {section.items.map((item, i) => (
                                    <div key={i} className={`${section.itemClassName} ${section.itemAlternating ? i % 2 === 0 ? `md:flex-row` : `md:flex-row-reverse` : ""}`}>
                                        {item.img && (
                                            <div className='flex-1 maplexxon-animation-translate-start'>
                                                <img src={item.img} alt={`item-${i}`} />
                                            </div>
                                        )}
                                        <div className={`${section.itemTextClassName} flex-1`}>
                                            {item.title && (
                                                <p className="text-xl lg:text-3xl text-maplexxon-blue font-bold maplexxon-animation-translate-start">{item.title}</p>
                                            )}
                                            {item.title2 && (
                                                <p className="text-lg lg:text-2xl text-maplexxon-blue font-bold maplexxon-animation-translate-start">{item.title2}</p>
                                            )}
                                            {item.title3 && (
                                                <p className="text-sm lg:text-base text-maplexxon-blue font-bold maplexxon-animation-translate-start">{item.title3}</p>
                                            )}
                                            {item.description && (
                                                <p className="text-sm lg:text-base maplexxon-animation-translate-start">{item.description}</p>
                                            )}
                                            {item.list?.length > 0 && (
                                                <ul className=''>
                                                    {item.list.map((listitem, i) => (
                                                        <li key={i} className='flex items-center gap-2 mb-2 maplexxon-animation-translate-start'>
                                                            <div>
                                                                <CheckCircleIcon
                                                                    width='16'
                                                                    height='16'
                                                                />
                                                            </div>
                                                            <p className='text-xs lg:text-sm'>{listitem.text}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                )
            })}

            {product?.details?.useCases && (
                <section className='bg-white'>
                    <div className='max-w-main py-8 lg:py-16 flex flex-col items-center'>
                        <p className='text-xl lg:text-3xl font-bold mb-6 lg:mb-12 maplexxon-animation-translate-start'>See use cases</p>
                        <div className="flex flex-wrap gap-4 lg:gap-8 justify-center mb-6 lg:mb-12">
                            {product?.details?.useCases?.map((item, i) => {
                                const Icon = item.icon
                                return (
                                    <div key={i} className='bg-maplexxon-purple-light w-use-case-item rounded-lg p-3 lg:p-6 flex flex-col gap-2 lg:gap-4 maplexxon-animation-scale-start'>
                                        <Icon
                                            height='40'
                                            width='40'
                                        />
                                        <p className='text-base lg:text-base font-bold mb-auto'>{item.label}</p>
                                        <div>
                                            <ArrowRightLongIcon
                                                className="ml-auto w-6 h-2 lg:w-10 lg:h-4"
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <button className='py-2 px-4 md:px-8 bg-maplexxon-blue text-white rounded-md'>View pricing details</button>
                    </div>
                </section>
            )}

            <ContactWhatsapp />
            <FAQ />
        </div>
    )
}

export default Product