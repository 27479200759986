import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup"

import CloseIcon from 'assets/icons/CloseIcon'
import { countryPhoneCodes } from 'utils/constants'
import { sendRequestDemo } from 'api';
import { useRequestDemoModal } from 'context/RequestDemoModalContext';
import SelectInput from 'components/Input/SelectInput';

function RequestDemo({
    onClose,
}) {
    const { setRequestDemoModalOpen } = useRequestDemoModal();
    const [submitSuccess, setSubmitSuccess] = useState(false)

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    } = useFormik({
        initialValues: {
            full_name: "",
            email: "",
            phone_country: "",
            phone_number: "",
            company_name: "",
            company_size: "",
            department: "",
            job_position: "",
            social_media_type: "",
            social_media_account: "",
            how_did_you_hear: "",
            marketing: false,
        },
        validationSchema: Yup.object({
            full_name: Yup.string().required("Full name is required"),
            email: Yup.string().email("Invalid email address").required("Email address is required"),
            phone_country: Yup.string(),
            phone_number: Yup.number(),
            company_name: Yup.string(),
            company_size: Yup.string(),
            department: Yup.string(),
            job_position: Yup.string(),
            social_media_type: Yup.string(),
            social_media_account: Yup.string(),
            how_did_you_hear: Yup.string(),
            marketing: Yup.boolean(),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            const res = await sendRequestDemo(
                values?.full_name,
                values?.email,
                values?.phone_country,
                values?.phone_number,
                values?.company_name,
                values?.company_size,
                values?.department,
                values?.job_position,
                values?.social_media_type,
                values?.social_media_account,
                values?.how_did_you_hear,
                values?.marketing,
            )
            setSubmitting(false)
            if (res?.success) {
                setSubmitSuccess(true)
                setTimeout(() => {
                    setRequestDemoModalOpen(false)
                }, 3000)
            }
        },
    })

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => document.body.classList.remove('modal-open')
    }, [])

    if (submitSuccess) {
        return (
            <div className='fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-40 mx-2'>
                <div className='bg-maplexxon-blue text-white z-50 max-w-main h-full max-h-[35vh] sm:max-h-[40vh] rounded-lg sm:rounded-3xl px-4 py-4 sm:px-8 sm:py-8 space-y-2 sm:space-y-6 flex flex-col'>
                    <div className='text-right'>
                        <button onClick={onClose}>
                            <CloseIcon className='fill-white h-5 sm:h-8 w-5 sm:w-8' />
                        </button>
                    </div>
                    <div className='text-center max-w-lg mx-auto px-2 sm:px-4 space-y-2 sm:space-y-6'>
                        <p className='text-lg lg:text-2xl font-bold'>Thank you for your request</p>
                        <p className='text-sm lg:text-lg leading-snug sm:leading-snug'>Our customer assistant will be in touch with you shortly to assist further on the demo schedule. <br /> Have a good day!</p>
                    </div>
                </div>
                <div className='fixed bg-white/50 blur-lg top-0 bottom-0 left-0 right-0' />
            </div>
        )
    }

    return (
        <div className='fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-40 mx-2'>
            <div className='bg-maplexxon-blue text-white z-50 max-w-main w-full h-full max-h-[80vh] sm:max-h-[90vh] rounded-lg sm:rounded-3xl px-4 py-4 sm:px-8 sm:py-8 space-y-2 sm:space-y-4 flex flex-col'>
                <div className='text-right'>
                    <button onClick={onClose}>
                        <CloseIcon className='fill-white h-5 sm:h-8 w-5 sm:w-8' />
                    </button>
                </div>
                <div className='text-center max-w-lg mx-auto space-y-2 sm:space-y-4'>
                    <p className='text-lg lg:text-2xl font-bold'>Request a demo</p>
                    <p className='text-sm lg:text-lg leading-snug sm:leading-snug'>Find out how our solutions can help your business. Schedule a virtual demo with our team.</p>
                </div>
                <div className='flex flex-col w-full max-w-lg mx-auto space-y-6 overflow-y-auto px-2 sm:px-4'>
                    <div className='flex flex-col gap-2'>
                        <label className='text-xs lg:text-sm font-bold uppercase'>Full name</label>
                        <input
                            autoComplete='off'
                            name="full_name"
                            value={values.full_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className='text-xs lg:text-sm px-4 py-2 rounded-lg text-black'
                            placeholder='Fill in your full name'
                        />
                        {errors?.full_name && touched?.full_name && (
                            <p className='text-xs text-red-300'>{errors?.full_name}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-xs lg:text-sm font-bold uppercase'>Email address</label>
                        <input
                            autoComplete='off'
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className='text-xs lg:text-sm px-4 py-2 rounded-lg text-black'
                            placeholder='Fill in your email address'
                        />
                        {errors?.email && touched?.email && (
                            <p className='text-xs text-red-300'>{errors?.email}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-xs lg:text-sm font-bold uppercase'>Phone number</label>
                        <div className='flex gap-2 sm:gap-4'>
                            <SelectInput
                                value={values?.phone_country}
                                name="phone_country"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='text-xs lg:text-sm px-2 py-2 rounded-lg w-20 sm:w-32 text-black'
                                options={[
                                    {
                                        label: "Select your phone country code",
                                        value: ""
                                    },
                                    ...countryPhoneCodes.map(code => ({
                                        label: `${code.name} (${code.code})`,
                                        value: `${code.name} (${code.code})`
                                    }))
                                ]}
                            />
                            <input
                                autoComplete='off'
                                type="number"
                                name="phone_number"
                                value={values.phone_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='text-xs lg:text-sm px-4 py-2 rounded-lg flex-1 text-black'
                                placeholder='Fill in your phone number'
                            />
                        </div>
                        {((errors?.phone_country && touched?.phone_country) || (errors?.phone_number && touched?.phone_number)) && (
                            <p className='text-xs text-red-300'>{errors?.phone_number}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-xs lg:text-sm font-bold uppercase'>Company name</label>
                        <input
                            autoComplete='off'
                            name="company_name"
                            value={values.company_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className='bg-white text-xs lg:text-sm px-4 py-2 rounded-lg text-black'
                            placeholder='Fill in your company name'
                        />
                        {errors?.company_name && touched?.company_name && (
                            <p className='text-xs text-red-300'>{errors?.company_name}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-xs lg:text-sm font-bold uppercase'>Company size</label>
                        <SelectInput
                            name="company_size"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.company_size}
                            className='text-xs lg:text-sm px-2 rounded-lg text-black bg-white'
                            options={[
                                {
                                    label: "Select your company size",
                                    value: ""
                                },
                                {
                                    label: "1 - 15",
                                    value: "1 - 15"
                                },
                                {
                                    label: "16 - 100",
                                    value: "16 - 100"
                                },
                                {
                                    label: "101 - 500",
                                    value: "101 - 500"
                                },
                                {
                                    label: "> 500",
                                    value: "> 500"
                                },
                            ]}
                        />
                        {errors?.company_size && touched?.company_size && (
                            <p className='text-xs text-red-300'>{errors?.company_size}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-xs lg:text-sm font-bold uppercase'>Department</label>
                        <SelectInput
                            name="department"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.department}
                            className='text-xs lg:text-sm px-2 rounded-lg text-black bg-white'
                            options={[
                                {
                                    label: "Select your department",
                                    value: ""
                                },
                                {
                                    label: "Dev / Engineering",
                                    value: "Dev / Engineering"
                                },
                                {
                                    label: "IT",
                                    value: "IT"
                                },
                                {
                                    label: "Sales",
                                    value: "Sales"
                                },
                                {
                                    label: "Support & Success",
                                    value: "Support & Success"
                                },
                                {
                                    label: "Marketing",
                                    value: "Marketing"
                                },
                                {
                                    label: "Finance / Legal / Ops",
                                    value: "Finance / Legal / Ops"
                                },
                                {
                                    label: "HR / People",
                                    value: "HR / People"
                                },
                                {
                                    label: "Strategy / Biz Dev",
                                    value: "Strategy / Biz Dev"
                                },
                            ]}
                        />
                        {errors?.department && touched?.department && (
                            <p className='text-xs text-red-300'>{errors?.department}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-xs lg:text-sm font-bold uppercase'>Job position</label>
                        <input
                            autoComplete='off'
                            name="job_position"
                            value={values.job_position}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className='bg-white text-xs lg:text-sm px-4 py-2 rounded-lg text-black'
                            placeholder='Fill in your job position'
                        />
                        {errors?.job_position && touched?.job_position && (
                            <p className='text-xs text-red-300'>{errors?.job_position}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-xs lg:text-sm font-bold uppercase'>Social media</label>
                        <div className='flex gap-2 sm:gap-4'>
                            <SelectInput
                                name="social_media_type"
                                value={values?.social_media_type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='text-xs lg:text-sm px-2 rounded-lg w-20 sm:w-32 text-black bg-white'
                                options={[
                                    {
                                        label: "Select your the social media type",
                                        value: ""
                                    },
                                    {
                                        label: "Instagram",
                                        value: "Instagram"
                                    },
                                    {
                                        label: "Facebook",
                                        value: "Facebook"
                                    },
                                    {
                                        label: "Linkedin",
                                        value: "Linkedin"
                                    },
                                    {
                                        label: "Twitter",
                                        value: "Twitter"
                                    },
                                    {
                                        label: "Youtube",
                                        value: "Youtube"
                                    },
                                    {
                                        label: "Tiktok",
                                        value: "Tiktok"
                                    }
                                ]}
                            />
                            <input
                                autoComplete='off'
                                name="social_media_account"
                                value={values.social_media_account}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='text-xs lg:text-sm px-4 py-2 rounded-lg flex-1 text-black'
                                placeholder='Fill in your social media'
                            />
                        </div>
                        {((errors?.social_media_type && touched?.social_media_type) || (errors?.social_media_account && touched?.social_media_account)) && (
                            <p className='text-xs text-red-300'>{errors?.social_media_account}</p>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-xs lg:text-sm font-bold uppercase'>How did you hear about us?</label>
                        <SelectInput
                            name="how_did_you_hear"
                            value={values?.how_did_you_hear}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className='text-xs lg:text-sm px-2 rounded-lg text-black bg-white'
                            options={[
                                {
                                    label: "Select on how did your hear about us",
                                    value: ""
                                },
                                {
                                    label: "Word of Mouth",
                                    value: "Word of Mouth"
                                },
                                {
                                    label: "Online Search",
                                    value: "Online Search"
                                },
                                {
                                    label: "Social Media",
                                    value: "Social Media"
                                },
                                {
                                    label: "Online Advertising",
                                    value: "Online Advertising"
                                },
                                {
                                    label: "Traditional Advertising",
                                    value: "Traditional Advertising"
                                },
                            ]}
                        />
                        {errors?.how_did_you_hear && touched?.how_did_you_hear && (
                            <p className='text-xs text-red-300'>{errors?.how_did_you_hear}</p>
                        )}
                    </div>
                    <div className='space-y-4'>
                        <p className='text-xs lg:text-sm'>By registering, you confirm that you agree to the processing of your personal data by Maplexxon as described in the <a className="underline" href='/privacypolicy' target='_blank' rel='noreferrer'>Privacy Policy</a></p>
                        <div className='flex items-start gap-2'>
                            <input
                                type='checkbox'
                                id="marketing"
                                name="marketing"
                                value={values.marketing}
                                onChange={handleChange}
                            />
                            <label htmlFor="marketing" className='text-xs lg:text-sm'>
                                Yes, I would like to receive marketing communications about the Maplexxon - PT Cendekia Edukasi Citra Tritunggal family of companies' products, services, and events. I can unsubscribe at any time.
                            </label>
                        </div>
                    </div>
                </div>
                <div className='max-w-lg mx-auto w-full space-y-4'>
                    <button disabled={isSubmitting} className='bg-black text-center w-full py-2 rounded-lg px-4 text-xs lg:text-sm' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
            <div className='fixed bg-white/50 blur-lg top-0 bottom-0 left-0 right-0' />
        </div>
    )
}

export default RequestDemo