import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import LinkedInIcon from 'assets/icons/LinkedInIcon';
import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { team } from 'utils/constants'

function Profile() {
    const { slug } = useParams();
    const pathname =
        window.location.pathname &&
        window.location.pathname.split('/')
    const params = pathname && pathname[pathname.length - 1]
    let profileSlug = slug ? slug : params
    const navigate = useNavigate();
    const profile = team.find(people => people.slug === profileSlug)

    return (
        <div>
            <section>
                <div className='max-w-main py-8 lg:py-16'>
                    <div className='flex items-center gap-12 mb-12'>
                        <img src={profile.image} alt={profile.name} />
                        <div>
                            <div className='flex mb-4 items-center gap-4'>
                                <p className='text-lg lg:text-2xl font-bold text-maplexxon-blue'>{profile.name}</p>
                                <LinkedInIcon />
                            </div>
                            <p className='text-base lg:text-xl font-medium'>{profile.role}</p>
                        </div>
                    </div>
                    <div className='text-base lg:text-xl' dangerouslySetInnerHTML={{ __html: profile.description }}>
                    </div>
                </div>
            </section>
            <section className='max-w-main py-8 lg:py-16'>
                <button className='flex gap-4 items-center py-2' onClick={() => navigate(-1)}>
                    <ArrowRightIcon className="rotate-180 w-5 h-5 fill-black" />
                    <p className='text-maplexxon-grey-2'>View all leadership team</p>
                </button>
            </section>
        </div>
    )
}

export default Profile