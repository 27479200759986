import React, { useState } from 'react'

import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowDownIcon'

import { faq } from 'utils/constants/product'

function FAQ({
    showAll,
}) {
    const [FAQOpen, setFAQOpen] = useState([])

    const handleToggleFAQ = (index) => {
        if (FAQOpen.includes(index)) {
            setFAQOpen(prev => prev.filter(i => i !== index))
        } else {
            setFAQOpen(prev => ([...prev, index]))
        }
    }

    const toggleShowAll = () => {
        if (faq.length === FAQOpen.length) {
            setFAQOpen([])
        } else {
            const indexes = faq.map((item, i) => i)
            setFAQOpen(indexes)
        }
    }

    const showFAQLength = 5
    const selectedFAQ = showAll ? faq : faq.slice(0, showFAQLength)

    return (
        <section className='bg-white'>
            <div className='max-w-main py-8 lg:py-16 flex flex-col items-center'>
                <p className='text-center uppercase text-base lg:text-xl tracking-widest mb-4 md:mb-8 maplexxon-animation-translate-start'>FAQ</p>
                <p className='text-xl lg:text-3xl font-bold mb-6 lg:mb-12 maplexxon-animation-translate-start'>Frequently Ask Questions</p>
                <div className='flex flex-col w-full mb-4 lg:mb-8'>
                    <div className='flex justify-end mb-2 md:px-8'>
                        <button
                            className='uppercase text-xs md:text-sm'
                            onClick={toggleShowAll}
                        >
                            {faq.length === FAQOpen.length ? "Hide all" : "Show all"}
                        </button>
                    </div>
                    {selectedFAQ.map((item, i) => (
                        <div key={i} className='border-y border-maplexxon-grey-light-2 py-2 md:py-6 md:px-8 transition-all maplexxon-animation-translate-start'>
                            <div className='flex items-center justify-between gap-2 cursor-pointer select-none' onClick={() => handleToggleFAQ(i)}>
                                <p className='text-base lg:text-xl font-medium'>{item.question}</p>
                                <button className={`${FAQOpen.includes(i) ? "rotate-180" : "rotate-0"} transition-all`} >
                                    <KeyboardArrowDownIcon className="h-6 w-3 fill-maplexxon-grey-2" />
                                </button>
                            </div>
                            {FAQOpen.includes(i) && (
                                <p className='mt-2 md:mt-4 text-xs lg:text-sm'>
                                    {item.answer}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
                {!showAll && (
                    <a className='uppercase text-sm sm:text-base' href="/faq">
                        View more
                    </a>
                )}
            </div>
        </section>
    )
}

export default FAQ