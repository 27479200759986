import React from 'react'

function LearningPlatformIcon({
    className,
    width = "67",
    height = "62",
    fill = "#8000CF"
}) {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 67 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.508 45.8628H16.6774V50.0321H8.33869V8.33869H4.16934V54.2015H16.6774V58.3708H0V4.16934H8.33869V0H20.8467C22.7577 0 24.5926 0.293157 26.3516 0.879471C28.1105 1.46578 29.75 2.34526 31.2701 3.51788C32.7684 2.34526 34.3971 1.46578 36.156 0.879471C37.915 0.293157 39.7608 0 41.6934 0H54.2015V4.16934H62.5401V30.8466L58.3708 28.9574V8.33869H54.2015V27.0682L50.0321 25.1464V4.16934H41.6934C40.1516 4.16934 38.675 4.42993 37.2635 4.95109C35.852 5.47226 34.5491 6.24316 33.3547 7.26378V27.0682L29.1854 28.9574V7.26378C28.0128 6.26487 26.7207 5.50484 25.3092 4.98367C23.8977 4.4625 22.4102 4.19106 20.8467 4.16934H12.508V45.8628ZM58.3708 54.1689L43.7781 61.4978L29.1854 54.1689C29.1854 53.3871 29.1963 52.562 29.218 51.6933C29.2397 50.8247 29.3266 49.9453 29.4786 49.0549C29.6306 48.1646 29.8477 47.3286 30.13 46.5468C30.4123 45.7651 30.8032 45.0376 31.3026 44.3644L25.0161 41.498V60.4555H20.8467V39.6088L43.7781 29.1854L66.7095 39.6088L56.2536 44.3644L56.5141 44.7227C56.9702 45.3959 57.3176 46.1234 57.5565 46.9051C57.7953 47.6869 57.9799 48.4795 58.1102 49.2829C58.2405 50.0864 58.3165 50.9116 58.3382 51.7585C58.3599 52.6054 58.3708 53.4088 58.3708 54.1689ZM43.7781 33.7456L30.9118 39.6088L43.7781 45.4719L56.6444 39.6088L43.7781 33.7456ZM43.7781 56.8399L54.2015 51.5956C54.1797 51.031 54.1363 50.5207 54.0712 50.0647C54.006 49.6087 53.8866 49.1635 53.7129 48.7292C53.5391 48.2949 53.3654 47.8714 53.1917 47.4589C53.018 47.0463 52.7682 46.5902 52.4425 46.0908L43.7781 50.0321L35.1137 46.0908C34.788 46.6119 34.5274 47.0788 34.3319 47.4914C34.1365 47.904 33.9628 48.3166 33.8108 48.7292C33.6588 49.1418 33.561 49.5761 33.5176 50.0321C33.4742 50.4881 33.4307 51.0202 33.3873 51.6282L43.7781 56.8399Z" fill={fill} />
        </svg>
    )
}

export default LearningPlatformIcon