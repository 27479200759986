import React from 'react'

function VideoIcon({
    className,
    width = "62",
    height = "46",
    fill = "#8000CF"
}) {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 62 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 38.3333V7.66667C0 5.63334 0.807736 3.68329 2.24551 2.24551C3.68329 0.807735 5.63334 0 7.66667 0H53.6667C55.7 0 57.65 0.807735 59.0878 2.24551C60.5256 3.68329 61.3333 5.63334 61.3333 7.66667V38.3333C61.3333 40.3667 60.5256 42.3167 59.0878 43.7545C57.65 45.1923 55.7 46 53.6667 46H7.66667C5.63334 46 3.68329 45.1923 2.24551 43.7545C0.807736 42.3167 0 40.3667 0 38.3333ZM26.0283 11.8565C25.7417 11.6524 25.4045 11.5312 25.0536 11.5061C24.7027 11.4809 24.3516 11.5529 24.0389 11.714C23.7261 11.8751 23.4637 12.1192 23.2805 12.4196C23.0973 12.7199 23.0002 13.0649 23 13.4167V32.5833C23.0002 32.9351 23.0973 33.2801 23.2805 33.5804C23.4637 33.8808 23.7261 34.1249 24.0389 34.286C24.3516 34.4471 24.7027 34.5191 25.0536 34.4939C25.4045 34.4688 25.7417 34.3475 26.0283 34.1435L39.445 24.5602C39.6935 24.3829 39.896 24.1488 40.0357 23.8774C40.1754 23.606 40.2483 23.3052 40.2483 23C40.2483 22.6948 40.1754 22.3939 40.0357 22.1226C39.896 21.8512 39.6935 21.6171 39.445 21.4398L26.0283 11.8565Z" fill={fill} />
        </svg>

    )
}

export default VideoIcon