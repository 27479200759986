import React from 'react'

function CustomerPaymentIcon({
    className = "h-14 w-14 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 63 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3493 21.5321C24.1162 21.5321 28.8346 16.8137 28.8346 11.0468C28.8346 5.27991 24.1162 0.561523 18.3493 0.561523C12.5824 0.561523 7.86397 5.27991 7.86397 11.0468C7.86397 16.8137 12.5824 21.5321 18.3493 21.5321ZM28.8346 42.5027V28.6097C25.9511 27.5612 22.2813 26.7748 18.3493 26.7748C8.12611 26.7748 0 31.4932 0 37.2601V42.5027H28.8346ZM57.6691 0.561523H39.3199C36.4364 0.561523 34.0772 2.92072 34.0772 5.80417V37.2601C34.0772 40.1435 36.4364 42.5027 39.3199 42.5027H57.6691C60.5526 42.5027 62.9118 40.1435 62.9118 37.2601V5.80417C62.9118 2.92072 60.5526 0.561523 57.6691 0.561523ZM47.1838 37.2601H41.9412V5.80417H47.1838V37.2601Z" />
        </svg>
    )
}

export default CustomerPaymentIcon