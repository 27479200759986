import React from 'react'

import product1Item1 from "assets/images/products/product1/item1.png"
import WhatsappIcon from 'assets/icons/WhatsappIcon'

function ContactWhatsapp() {
    return (
        <section className=' bg-maplexxon-grey-light'>
            <div className='max-w-main py-4 lg:py-8 flex flex-col-reverse md:flex-row gap-4 md:gap-16 items-center'>
                <div className='w-full lg:w-7/12 maplexxon-animation-scale-start'>
                    <img src={product1Item1} alt='have-questions' />
                </div>
                <div className='space-y-4 sm:space-y-6 text-center sm:text-left'>
                    <p className='text-xl lg:text-3xl font-bold maplexxon-animation-translate-start'>Still have questions?</p>
                    <p className='text-base lg:text-xl maplexxon-animation-translate-start'>No worries. Our team are here to support you or simply schedule a virtual consultation for free.</p>
                    <button onClick={() => window.open(`https://wa.me/+6281385898240`, "_blank")} className='mx-auto sm:mx-0 flex bg-maplexxon-blue text-white font-medium px-8 py-2 rounded-md gap-2 items-center text-sm lg:text-base maplexxon-animation-translate-start'>
                        <WhatsappIcon />
                        Contact our Whatsapp
                    </button>
                </div>
            </div>
        </section>
    )
}

export default ContactWhatsapp