import React from 'react'

function CircleIcon({
    className = "w-3 h-3 fill-none"
}) {
    return (
        <svg className={className} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="5.5" stroke="black" />
        </svg>
    )
}

export default CircleIcon