import React from 'react'

function MenuIcon({
    width = "24",
    height = "24",
    fill = "black"
}) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6_13413)">
                <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_6_13413">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MenuIcon