import React from 'react'
import { team } from 'utils/constants'

function Team() {
    return (
        <section className='bg-maplexxon-blue-light-2'>
            <div className='max-w-main py-8 lg:py-16 flex flex-col items-center'>
                <p className='text-center uppercase text-base lg:text-xl tracking-widest mb-4 md:mb-8 text-white maplexxon-animation-translate-start'>Leadership</p>
                <p className='text-xl lg:text-3xl font-bold mb-6 lg:mb-12 text-center md:text-left text-white maplexxon-animation-translate-start'>The team behind Maplexxon</p>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full justify-items-center'>
                    {team.map((people, i) => (
                        <div key={i} className='bg-white py-4 flex flex-col maplexxon-animation-scale-start'>
                            <p className='text-base lg:text-xl font-bold mx-4 md:mx-8 mb-2 w-1/2'>{people.name}</p>
                            <p className='text-base lg:text-xl mx-4 md:mx-8 mb-4'>{people.role}</p>
                            <img className="object-cover mb-4" src={people.image} alt={people.name} />
                            <a href={`/profile/${people.slug}`} className='text-xs lg:text-sm mx-4 md:mx-8 text-right'>Read More</a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Team