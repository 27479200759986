import React from 'react'

import Team from 'components/Team/Team'

import { teamValues } from 'utils/constants/about'
import itSupportBanner from 'assets/images/banners/it_support_banner.png'

function About() {
    return (
        <div>
            <section className='bg-maplexxon-grey'>
                <div className='max-w-main py-16 lg:py-40 flex flex-col items-center'>
                    <h1 className='max-w-lg text-center text-xl lg:text-3xl leading-normal font-bold text-maplexxon-blue maplexxon-animation-translate-start'>
                        Helping your business grow without hassle
                    </h1>
                </div>
            </section>

            <section>
                <div className='max-w-main py-8 lg:py-16 flex flex-col items-center'>
                    <h1 className='max-w-xl text-center text-xl lg:text-3xl mb-12 font-bold text-maplexxon-blue maplexxon-animation-translate-start'>
                        About Maplexxon
                    </h1>
                    <p className='max-w-2xl text-center text-base lg:text-xl maplexxon-animation-translate-start'>
                        <span className='font-bold'>Maplexxon</span> is a B2B Software as a Service (SaaS) company that offers ready-made solutions, to help small to medium sized of business achieve success. We envision helping businesses to optimize their operations, enabling them to work smarter, faster and more efficient.
                    </p>
                    <div className='max-w-2xl text-center mt-6 sm:mt-12 space-y-2 sm:space-y-4'>
                        <div className='space-y-1 sm:space-y-2 maplexxon-animation-translate-start'>
                            <p className='text-lg lg:text-2xl font-bold'>
                                Vision
                            </p>
                            <p className='text-base lg:text-xl'>
                                Build software solutions for businesses to thrive in digital age.
                            </p>
                        </div>
                        <div className='space-y-1 sm:space-y-2 maplexxon-animation-translate-start'>
                            <p className='text-lg lg:text-2xl font-bold'>
                                Mission
                            </p>
                            <p className='text-base lg:text-xl'>
                                Simplifying process, scaling up your business.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className=''>
                <div className='max-w-main py-4 lg:py-8 flex flex-col items-center'>
                    <div className='cursor-pointer w-full' onClick={() => window.location.assign("/pricing")}>
                        <img className='w-full rounded-md sm:rounded-2xl maplexxon-animation-scale-start' src={itSupportBanner} alt="it_support_banner" />
                    </div>
                </div>
            </section>

            {/* <section className='border-b'>
                <div className='max-w-main py-8 lg:py-16 flex flex-col items-center'>
                    <h1 className='max-w-xl text-center text-xl lg:text-3xl mb-8 font-bold text-maplexxon-blue maplexxon-animation-translate-start'>
                        At Maplexxon, here are the values we believe in
                    </h1>
                    <p className='max-w-xl text-center text-base lg:text-xl mb-12 maplexxon-animation-translate-start'>
                        It becomes our DNA wherever we go, in anything we do
                    </p>
                    <div className='max-w-4xl grid grid-cols-1 md:grid-cols-2 justify-items-center gap-8'>
                        {teamValues.map((value, i) => {
                            const Icon = value.icon
                            return (
                                <div key={i} className='flex flex-col items-center md:items-start px-4 py-6 md:px-10 md:py-12 bg-maplexxon-blue-light rounded-2xl maplexxon-animation-scale-start'>
                                    <div className='mb-4'>
                                        <Icon
                                            width='80'
                                            height='80'
                                        />
                                    </div>
                                    <p className='text-base lg:text-xl font-bold mb-4'>{value.title}</p>
                                    <p className='text-xs lg:text-sm text-center md:text-left'>{value.description}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <Team />

            <section>
                <div className='max-w-main py-8 lg:py-16 grid grid-cols-1 md:grid-cols-5 items-center gap-4 md:gap-24'>
                    <div className='md:col-span-2'>
                        <p className='uppercase text-base lg:text-xl tracking-widest mb-2 md:mb-4 text-center md:text-left maplexxon-animation-translate-start'>Career</p>
                        <p className='text-xl lg:text-3xl font-bold mb-4 lg:mb-8 text-center md:text-left maplexxon-animation-translate-start'>Be a part of our growing team</p>
                        <p className='text-base lg:text-xl text-center md:text-left maplexxon-animation-translate-start'>Lorem ipsum dolor sit amet consectetur. Vitae lacus quis pharetra faucibus a enim vel risus. </p>
                    </div>
                    <div className='md:col-span-3'>
                        <div className='bg-maplexxon-grey w-full aspect-video rounded-3xl maplexxon-animation-scale-start'>

                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    )
}

export default About