import React from 'react'

function VulnerabilityTestIcon({
    className = "h-14 w-14 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 42 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.5455 23.0808C41.5455 35.8907 32.6825 47.8697 20.7728 50.7779C8.86304 47.8697 0 35.8907 0 23.0808V9.23234L20.7728 0L41.5455 9.23234V23.0808ZM20.7728 46.1617C29.4281 43.8536 36.9294 33.5596 36.9294 23.5886V12.2328L20.7728 5.03162L4.61617 12.2328V23.5886C4.61617 33.5596 12.1174 43.8536 20.7728 46.1617ZM27.2354 23.0808V19.6187C27.2354 16.3874 24.0041 13.8485 20.7728 13.8485C17.5414 13.8485 14.3101 16.3874 14.3101 19.6187V23.0808C12.9253 23.0808 11.5404 24.4657 11.5404 25.8505V33.9288C11.5404 35.5445 12.9253 36.9294 14.3101 36.9294H27.0046C28.6202 36.9294 30.0051 35.5445 30.0051 34.1597V26.0814C30.0051 24.4657 28.6202 23.0808 27.2354 23.0808ZM24.2349 23.0808H17.3106V19.6187C17.3106 17.7723 18.9263 16.6182 20.7728 16.6182C22.6192 16.6182 24.2349 17.7723 24.2349 19.6187V23.0808Z" />
        </svg>
    )
}

export default VulnerabilityTestIcon