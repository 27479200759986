import React from 'react'

function InfoIcon({
    className = "w-4 h-4 fill-white"
}) {
    return (
        <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6_12344)">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z" />
            </g>
            <defs>
                <clipPath id="clip0_6_12344">
                    <rect width="24" height="24" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default InfoIcon