import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import Navbar from 'components/Navbar/Navbar'
import Discover from 'components/Discover/Discover'
import Footer from 'components/Footer/Footer'
import Menu from 'components/Menu/Menu'

import { useAnimation } from 'utils/hooks'

import SupportChat from 'components/SupportChat/SupportChat';
import CookieConsent from 'components/Modal/CookieConsent/CookieConsent'
import { useRequestDemoModal } from 'context/RequestDemoModalContext'
import RequestDemo from 'components/Modal/CookieConsent/RequestDemo'

function MainContainer({ children }) {
    const { isRequestDemoModalOpen, setRequestDemoModalOpen } = useRequestDemoModal();

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [isOpenCookieConsent, setOpenCookieConsent] = useState(true);
    useAnimation()

    useEffect(() => {
        window.onscroll = function () {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
    }, []);

    return (
        <div>
            <Menu
                className={`${isMenuOpen ? "translate-y-0 z-30 opacity-100 overflow-auto" : "-translate-y-full -z-50 opacity-0"}`}
                scrolled={scrolled}
                onClose={() => setMenuOpen(false)}
                onClickDemo={() => setRequestDemoModalOpen(true)}
            />
            <Navbar
                scrolled={scrolled}
                onClickMenuButton={() => setMenuOpen(true)}
                onClickDemo={() => setRequestDemoModalOpen(true)}
            />
            <div className={`${isMenuOpen ? "hidden" : 'inline'}`}>
                {children}
                <Discover />
                <Footer />
            </div>

            <SupportChat />
            {isOpenCookieConsent && window.location.pathname !== "/privacypolicy" && Cookies.get('cookie_consent') !== "true" && (
                <CookieConsent setOpenCookieConsent={setOpenCookieConsent} />
            )}

            {isRequestDemoModalOpen && (
                <RequestDemo
                    onClose={() => setRequestDemoModalOpen(false)}
                />
            )}
        </div>
    )
}

export default MainContainer