import EcommerceIcon from "assets/icons/EcommerceIcon"
import AppointmentIcon from "assets/icons/AppointmentIcon"
import VideoIcon from "assets/icons/VideoIcon"
import CouponIcon from "assets/icons/CouponIcon"
import LearningPlatformIcon from "assets/icons/LearningPlatformIcon"
import GuestManagementIcon from "assets/icons/GuestManagementIcon"
import HomeIcon from "assets/icons/HomeIcon"
import CompanyProfileIcon from "assets/icons/CompanyProfileIcon"

import adelaide from "assets/images/companies/adelaide.png"
import aesop from "assets/images/companies/aesop.png"
import glossier from "assets/images/companies/glossier.png"
import mecca from "assets/images/companies/mecca.png"
import rmit from "assets/images/companies/rmit.png"

import product1main from "assets/images/products/product1/main.png"
import product1item1 from "assets/images/products/product1/item1.png"
import product1item2 from "assets/images/products/product1/item2.png"
import product1item3 from "assets/images/products/product1/item3.png"
import product1item4 from "assets/images/products/product1/item4.png"

import showcase4 from "assets/images/showcase/showcase-4.png"

import product1feature1 from "assets/images/products/product1/manage-and-track-sales.png"
import product1feature2 from "assets/images/products/product1/inventory-and-order-management.png"
import product1feature3 from "assets/images/products/product1/inventory-custom-variants.png"
import product1feature4 from "assets/images/products/product1/transaction-details-and-history.png"
import product1feature5 from "assets/images/products/product1/simple-payment-and-checkout.png"
import product1feature6 from "assets/images/products/product1/shipping-carrier.png"
import product1feature7 from "assets/images/products/product1/chat-support.png"
import product1feature8 from "assets/images/products/product1/automated-emails.png"
import product1feature9 from "assets/images/products/product1/promo-coupons.png"
import product1feature10 from "assets/images/products/product1/multiple-product-showcase.png"


import product2main from "assets/images/products/product2/appointment-main.png"
import product2feature1 from "assets/images/products/product2/personalized-branding.png"
import product2feature2 from "assets/images/products/product2/complete-with-payment-system.png"
import product2feature3 from "assets/images/products/product2/automated-workflows.png"
import product2feature4 from "assets/images/products/product2/detailed-booking-reports.png"
import product2feature5 from "assets/images/products/product2/modify-slot-through-cms.png"
import product2feature6 from "assets/images/products/product2/responsive-site.png"
import product2feature7 from "assets/images/products/product2/highlight-appointment.png"
import product2feature8 from "assets/images/products/product2/order-details.png"


import product3main from "assets/images/products/product3/company-profile-main.png"
import product3feature1 from "assets/images/products/product3/premium-web-interface.png"
import product3feature2 from "assets/images/products/product3/cms-complete.png"
import product3feature3 from "assets/images/products/product3/blog-feature.png"
import product3feature4 from "assets/images/products/product3/responsive-site.png"


const style1 = {
    className: "bg-maplexxon-grey-light",
    itemGroupClassName: "grid grid-cols-1 sm:grid-cols-2 gap-12 lg:gap-8 justify-start items-center mt-12 max-w-3xl mx-auto",
    itemClassName: "flex gap-2 lg:gap-4 flex-col items-center justify-start",
    itemTextClassName: "flex flex-col gap-2 lg:gap-4 text-center",
}

const style2 = {
    className: "bg-white",
    itemGroupClassName: "flex flex-col flex-wrap gap-8 justify-center items-center mt-20",
    itemClassName: `flex flex-col md:flex-row gap-2 md:gap-20 md:w-item-1 items-center justify-start`,
    itemAlternating: true,
    itemTextClassName: "lg:w-1/2 flex flex-col gap-2 lg:gap-4 text-center lg:text-left",
}

const style3 = {
    className: "bg-maplexxon-grey-light",
    itemGroupClassName: "flex flex-wrap gap-8 justify-center items-center mt-12",
    itemClassName: "flex gap-8 lg:w-item-4 flex-col items-center justify-center",
    itemTextClassName: "flex flex-col gap-2 lg:gap-4 text-left",
}

export const products = [
    {
        id: 1,
        name: "Ecommerce Website",
        description: "Professionally designed e-commerce website to grow your online store.",
        slug: "ecommerce-website",
        icon: EcommerceIcon,
        beta: false,
        details: {
            title: "All-in-one online store to sell your products",
            subtitle: (
                <>
                    <p>
                        Focus on selling and managing your online store with our ready-to-use site templates.
                    </p><br />
                    <p>
                        Powered with Content Management System (CMS) to manage and maintain your site contents. Our CMS are designed professionally with the user-friendly interface.
                    </p>
                </>
            ),
            img: product1main,
            brands: [
                {
                    id: 1,
                    name: "aesop",
                    src: aesop
                },
                {
                    id: 2,
                    name: "rmit",
                    src: rmit
                },
                {
                    id: 3,
                    name: "glossier",
                    src: glossier
                },
                {
                    id: 4,
                    name: "mecca",
                    src: mecca
                },
                {
                    id: 5,
                    name: "adelaide",
                    src: adelaide
                },
            ],
            body: [
                {
                    title: "Build ecommerce site simpler with easy-to-use interface",
                    className: style1.className,
                    itemGroupClassName: style1.itemGroupClassName,
                    itemClassName: style1.itemClassName,
                    itemTextClassName: style1.itemTextClassName,
                    items: [
                        {
                            img: product1item1,
                            title2: "You can launch your online store faster",
                            description: "Launch your products (Time-to-Market) faster by using our pre-built software solutions that are beautifully crafted with cost-effective solutions on the development side."
                        },
                        {
                            img: product1item2,
                            title2: "Integrated with leading payment system",
                            description: "Secure payments powered by Midtrans, integrated payment system with variety of payment methods: Virtual Account, CreditCard, Pay at Minimarkets, etc."
                        },
                        {
                            img: product1item3,
                            title2: "Automated process from receiving orders to shipment",
                            description: "Eliminate the needs for manual tasks, manage fulfillment to product delivery with our automated order management system."
                        },
                        {
                            img: product1item4,
                            title2: "Responsive site that are accessible through website, tablet or mobile",
                            description: "Seamlessly connecting your customer dynamically from various screens."
                        },
                    ]
                },
                {
                    name: "features",
                    title: "A commerce platform to support your business",
                    description: "Our e-commerce platform are built with complete features, all you need is just launch your store and directly selling.",
                    className: style2.className,
                    itemGroupClassName: style2.itemGroupClassName,
                    itemClassName: style2.itemClassName,
                    itemAlternating: style2.itemAlternating,
                    itemTextClassName: style2.itemTextClassName,
                    items: [
                        {
                            img: product1feature1,
                            title: "Manage and track your sales through admin panel",
                            description: "Easily manage and track your store sales from the dashboard provided, as well as monitoring sales and track customer recent orders."
                        },
                        {
                            img: product1feature2,
                            title: "Inventory and order management",
                            description: "Manage inventory (product SKUs) to customer orders all in one place."
                        },
                        {
                            img: product1feature3,
                            title: "CMS panel to support",
                            description: "Add, edit, delete products easily with customisable variants on each product."
                        },
                        {
                            img: product1feature4,
                            title: "Transaction details and history",
                            description: "View transaction details in order to prepare and pack your customer orders without hassle, you can always print transaction details directly."
                        },
                        {
                            img: product1feature5,
                            title: "Simple payment and checkout process for customers",
                            description: (
                                <>
                                    <p>Our e-commerce platform offers a direct payment and checkout with variety of payment methods, ranging from Virtual Accounts, e-wallets, QRIS, credit cards and others.</p>
                                    <br />
                                    <p>Integrated with the industry-leading payment system, by Midtrans.</p>
                                </>
                            )
                        },
                        {
                            img: product1feature6,
                            title: "Adding flexible shipping options",
                            description: (
                                <>
                                    <p>Features to add delivery services with a variety of flexible options for your customers.
                                    </p>
                                    <br />
                                    <p>Easily manage and process orders with shipping costs calculated automatically.</p>
                                </>
                            )
                        },
                        {
                            img: product1feature7,
                            title: "Chat widget with auto replies",
                            description: "The e- commerce site comes with a live chat widget that makes it easier for customers to interact directly with your store's support team."
                        },
                        {
                            img: product1feature8,
                            title: "Automated emails",
                            description: "Embedded with end-to-end automated emails from the moment your customer made an order to shipment."
                        },
                        {
                            img: product1feature9,
                            title: "Personalized coupons",
                            description: "Set and manage coupon eligibility by selecting entire or selected products for each coupon. By this personalized coupon feature, allowing you to create campaigns for customers."
                        },
                        {
                            img: product1feature10,
                            title: "Product catalog showcase",
                            description: "Showcase the best of your absolute products to your customers by adding multiple images.",
                        },
                    ]
                },
                // {
                //     title: "Lorem ipsum dolor sit amet",
                //     className: style3.className,
                //     itemGroupClassName: style3.itemGroupClassName,
                //     itemClassName: style3.itemClassName,
                //     itemTextClassName: style3.itemTextClassName,
                //     items: [
                //         {
                //             title3: "Lorem ipsum",
                //             list: [
                //                 {
                //                     text: "Custom shipping fee",
                //                 },
                //                 {
                //                     text: "Add product variants",
                //                 },
                //                 {
                //                     text: "Customizable field for product information",
                //                 },
                //             ]
                //         },
                //         {
                //             title3: "Lorem ipsum",
                //             list: [
                //                 {
                //                     text: "Custom shipping fee",
                //                 },
                //                 {
                //                     text: "Add product variants",
                //                 },
                //                 {
                //                     text: "Customizable field for product information",
                //                 },
                //             ]
                //         },
                //         {
                //             title3: "Lorem ipsum",
                //             list: [
                //                 {
                //                     text: "Custom shipping fee",
                //                 },
                //                 {
                //                     text: "Add product variants",
                //                 },
                //                 {
                //                     text: "Customizable field for product information",
                //                 },
                //             ]
                //         },
                //         {
                //             title3: "Lorem ipsum",
                //             list: [
                //                 {
                //                     text: "Custom shipping fee",
                //                 },
                //                 {
                //                     text: "Add product variants",
                //                 },
                //                 {
                //                     text: "Customizable field for product information",
                //                 },
                //             ]
                //         },
                //     ]
                // },
            ],
            // useCases: [
            //     {
            //         icon: HomeIcon,
            //         label: "Fashion"
            //     },
            //     {
            //         icon: HomeIcon,
            //         label: "Home and decor"
            //     },
            //     {
            //         icon: HomeIcon,
            //         label: "Food"
            //     },
            //     {
            //         icon: HomeIcon,
            //         label: "Real estate"
            //     },
            //     {
            //         icon: HomeIcon,
            //         label: "Local business"
            //     },
            //     {
            //         icon: HomeIcon,
            //         label: "Restaurant"
            //     },
            // ]
        },
        pricings: [
            {
                id: 1,
                name: "Starter",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 9000000,
                monthlyPrice: 799000,
                discountedMonthlyPrice: 708333,
                annualPrice: 8500000,
                priceDescriptionText: "Save up to 15% annually",
                features: [
                    {
                        text: "Custom domain",
                    },
                    {
                        text: "Product catalog management",
                    },
                    {
                        text: "SSL certificate",
                    },
                    {
                        text: "Automatic data backup",
                    },
                    // {
                    //     text: "Upload bulk products",
                    // },
                    {
                        text: "Support and maintenance",
                    },
                    {
                        text: "Responsive web design",
                    },
                    {
                        text: "Integrated with payment system",
                    },
                    {
                        text: "Monthly orders limit",
                        value: "500"
                    },
                    {
                        text: "Number of products",
                        value: "50"
                    },
                    {
                        text: "Maplexxon logo on footer",
                    },
                    {
                        text: "Promotional pop up banners"
                    },
                ]
            },
            {
                id: 2,
                name: "Essential",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 12000000,
                monthlyPrice: 1199000,
                discountedMonthlyPrice: 1041700,
                annualPrice: 12500000,
                priceDescriptionText: "Save up to 15% annually",
                mostPopular: true,
                features: [
                    {
                        text: "Custom domain",
                    },
                    {
                        text: "Product catalog management",
                    },
                    {
                        text: "SSL certificate",
                    },
                    {
                        text: "Automatic data backup",
                    },
                    // {
                    //     text: "Upload bulk products",
                    // },
                    {
                        text: "Support and maintenance",
                    },
                    {
                        text: "Responsive web design",
                    },
                    {
                        text: "Integrated with payment system",
                    },
                    {
                        text: "Monthly orders limit",
                        value: "1500"
                    },
                    {
                        text: "Number of products",
                        value: "150"
                    },
                    {
                        text: "Multi user account access",
                        value: "Up to 2 accounts"
                    },
                    {
                        text: "In web chat widget"
                    },
                    {
                        text: "Promotional pop up banners"
                    },
                    {
                        text: "Low stock alert setting"
                    },
                    {
                        text: "Receipt download per user"
                    },
                    {
                        text: "Monthly sales report (by email)"
                    },
                    {
                        text: "Custom shipping fee"
                    },
                    {
                        text: "Promotional coupon system"

                    },
                    {
                        text: "Maplexxon logo on footer",

                    },
                ]
            },
            {
                id: 3,
                name: "Professional",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 18000000,
                monthlyPrice: 1899000,
                discountedMonthlyPrice: 1666700,
                annualPrice: 20000000,
                priceDescriptionText: "Save up to 15% annually",
                features: [
                    {
                        text: "Custom domain",
                    },
                    {
                        text: "Product catalog management",
                    },
                    {
                        text: "SSL certificate",
                    },
                    {
                        text: "Automatic data backup",
                    },
                    // {
                    //     text: "Upload bulk products",
                    // },
                    {
                        text: "Support and maintenance",
                    },
                    {
                        text: "Responsive web design",
                    },
                    {
                        text: "Integrated with payment system",
                    },
                    {
                        text: "Monthly orders limit",
                        value: "3000+"
                    },
                    {
                        text: "Number of products",
                        value: "300+"
                    },
                    {
                        text: "Multi user account access",
                        value: "Up to 4 accounts"
                    },
                    {
                        text: "In web chat widget"
                    },
                    {
                        text: "Promotional pop up banners"
                    },
                    {
                        text: "Low stock alert setting"
                    },
                    {
                        text: "Receipt download per user"
                    },
                    {
                        text: "Monthly sales report (by email)"
                    },
                    {
                        text: "Custom shipping fee"
                    },
                    {
                        text: "Promotional coupon system"
                    },
                    {
                        text: "Abandoned cart reminders",
                    },
                ]
            },
        ],
    },
    {
        id: 2,
        name: "Appointment and Schedulling Management",
        description: "Manage appointments and help your customers book services easily.",
        slug: "appointment-and-scheduling-management",
        icon: AppointmentIcon,
        beta: false,
        details: {
            title: "Give your customers a seamless booking experience",
            subtitle: "The Maplexxon software makes it easy for your customers to make direct booking or appointments through a website. Complete with automated workflows when booking is made.",
            img: product2main,
            brands: [
                {
                    id: 1,
                    name: "aesop",
                    src: aesop
                },
                {
                    id: 2,
                    name: "rmit",
                    src: rmit
                },
                {
                    id: 3,
                    name: "glossier",
                    src: glossier
                },
                {
                    id: 4,
                    name: "mecca",
                    src: mecca
                },
                {
                    id: 5,
                    name: "adelaide",
                    src: adelaide
                },
            ],
            body: [
                {
                    name: "features",
                    className: style2.className,
                    itemGroupClassName: style2.itemGroupClassName,
                    itemClassName: style2.itemClassName,
                    itemAlternating: style2.itemAlternating,
                    itemTextClassName: style2.itemTextClassName,
                    items: [
                        {
                            img: product2feature1,
                            title: "Personalised site with your own branding",
                            description: "Have your appointment and schedulling site branded with your brand logo. Simply give your brand logo and select the colors and tone based on your preferences that are aligned with your brand."
                        },
                        {
                            img: product2feature2,
                            title: "Complete with payment system",
                            description: "You may take payment upfront once your customers book the appointment. Allowing customers to proceed the booking process with a variety of payment options."
                        },
                        {
                            img: product2feature3,
                            title: "Automated workflows",
                            description: "Once appointment is made by your customers, email reminders are automatically set up to make your administration hassle free."
                        },
                        {
                            img: product2feature7,
                            title: "Streamline schedulling process through calendar preview",
                            description: "Our automated system keeps both internal admin and your customners in the loop, reducing missed appointments and ensuring everyone stays on track."
                        },
                        {
                            img: product2feature4,
                            title: "Detailed booking reports",
                            description: "Analyze booking or appointment details database and turn into insightful marketing efforts. Booking reports will be automatically sent to administrators emails each week or month."
                        },
                        {
                            img: product2feature5,
                            title: "Modify slot availability through our powerful CMS",
                            description: "We provide Content Management System (CMS), enabling your internal admin to add or update slot availability for your services."
                        },
                        {
                            img: product2feature6,
                            title: "Responsive site that are accessible through website, tablet or mobile",
                            description: "Seamlessly connecting your customer dynamically from various screens."
                        },
                        {
                            img: product2feature8,
                            title: "View customer and order details just a single tap away",
                            description: "With just a few clicks, you can view order details, customer information, check appointment detail and status effortlessly."
                        },
                    ]
                },
            ],
        },
        pricings: [
            {
                id: 1,
                name: "Essential",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 8000000,
                monthlyPrice: 699000,
                discountedMonthlyPrice: 608333,
                annualPrice: 7300000,
                priceDescriptionText: "Save up to 15% annually",
                features: [
                    {
                        text: "Custom domain",
                    },
                    {
                        text: "SSL certificate",
                    },
                    {
                        text: "Automatic data backup",
                    },
                    {
                        text: "Support and maintenance",
                    },
                    {
                        text: "Responsive web design",
                    },
                    {
                        text: "Automated email workflows",
                    },
                    {
                        text: "Maplexxon logo on footer",
                    },
                    {
                        text: "Customer support via chat",
                    },
                ]
            },
            {
                id: 2,
                name: "Professional",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 10000000,
                monthlyPrice: 999000,
                discountedMonthlyPrice: 875000,
                annualPrice: 10500000,
                priceDescriptionText: "Save up to 15% annually",
                mostPopular: true,
                features: [
                    {
                        text: "Custom domain",
                    },
                    {
                        text: "SSL certificate",
                    },
                    {
                        text: "Automatic data backup",
                    },
                    {
                        text: "Support and maintenance",
                    },
                    {
                        text: "Responsive web design",
                    },
                    {
                        text: "Automated email workflows",
                    },
                    {
                        text: "Maplexxon logo on footer",
                    },
                    {
                        text: "Customer support via chat",
                    },
                    {
                        text: "Multi user account access",
                        value: "Up to 2 accounts"
                    },
                    {
                        text: "Integrated with payment system",
                    },
                    {
                        text: "Sync with Google Calendar",
                    },
                    {
                        text: "In web chat widget",
                    },
                    {
                        text: "Upload logo and brand colors",
                    },
                ]
            },
        ],
    },
    {
        id: 3,
        name: "Company Profile Site",
        description: "Present a professional website for your company or own brand.",
        slug: "company-profile-site",
        icon: CompanyProfileIcon,
        beta: false,
        details: {
            title: "Present a professional website for your company or own brand",
            subtitle: "Get the premium website with rich feature listings.",
            img: product3main,
            body: [
                {
                    name: "features",
                    className: style2.className,
                    itemGroupClassName: style2.itemGroupClassName,
                    itemClassName: style2.itemClassName,
                    itemAlternating: style2.itemAlternating,
                    itemTextClassName: style2.itemTextClassName,
                    items: [
                        {
                            img: product3feature1,
                            title: "Premium website interface",
                            description: "Give customers the best experience when accessing your website. The site comes with user-friendly interface"
                        },
                        {
                            img: product3feature2,
                            title: "Customizable website powered with CMS",
                            description: (
                                <>
                                    You can easily edit/update all website contents from the Content Management System (CMS). Maplexxon provides CMS training with our support team upon setting up your website.
                                    <br />
                                    <br />
                                    Additionally, we do offer a content management service to help you save your time so you can focus on running your business.
                                </>
                            )
                        },
                        {
                            img: product3feature3,
                            title: "Blogging features",
                            description: "Have your website complete with blog posts and its workflow. You can easily publish or save post as draft in the content editor."
                        },
                        {
                            img: product3feature4,
                            title: "Responsive site that are accessible through website, tablet or mobile",
                            description: "Seamlessly connecting your customer dynamically from various screens."
                        },
                        // {
                        //     img: product1item3,
                        //     title: "Build customer database through newsletter sign up",
                        // },
                    ]
                },
            ],
        },
        pricings: [
            {
                id: 1,
                name: "Essential",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 6000000,
                monthlyPrice: 650000,
                discountedMonthlyPrice: 583333,
                annualPrice: 7000000,
                priceDescriptionText: "Save up to 15% annually",
                features: [
                    {
                        text: "Custom domain",
                    },
                    {
                        text: "SSL certificate",
                    },
                    {
                        text: "Automatic data backup",
                    },
                    {
                        text: "Support and maintenance",
                    },
                    {
                        text: "Responsive web design",
                    },
                    {
                        text: "UI Design template",
                    },
                    {
                        text: "Maplexxon logo on footer",
                    },
                    {
                        text: "Customer support via chat",
                    },
                ]
            },
            {
                id: 2,
                name: "Professional",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 8000000,
                monthlyPrice: 950000,
                discountedMonthlyPrice: 850000,
                annualPrice: 10200000,
                priceDescriptionText: "Save up to 15% annually",
                mostPopular: true,
                features: [
                    {
                        text: "Custom domain",
                    },
                    {
                        text: "SSL certificate",
                    },
                    {
                        text: "Automatic data backup",
                    },
                    {
                        text: "Support and maintenance",
                    },
                    {
                        text: "Responsive web design",
                    },
                    {
                        text: "UI Design template",
                    },
                    {
                        text: "Maplexxon logo on footer",
                    },
                    {
                        text: "Customer support via chat",
                    },
                    {
                        text: "In web chat widget",
                    },
                    {
                        text: "Blog posts",
                    },
                    {
                        text: "Mailchimp integration for newsletter",
                    },
                    {
                        text: "Contact form page",
                    },
                    {
                        text: "Multi user account access",
                        value: "Up to 2 accounts"
                    },
                    {
                        text: "Contact form inquiry sent to email",
                    },
                    {
                        text: "Add downloadable file on specific pages",
                    },
                ]
            },
        ],
    },
    {
        id: 4,
        name: "On-demand Learning Platform and Microsite",
        description: "Create and share your own lessons through a ready-made learning platform.",
        slug: "on-demand-learning-platform-and-microsite",
        icon: LearningPlatformIcon,
        beta: true,
        details: {
            title: "All in one online store to sell your products",
            subtitle: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique. Nec in est cursus eu et id viverra scelerisque gravida. ",
            img: product1item4,
            brands: [
                {
                    id: 1,
                    name: "aesop",
                    src: aesop
                },
                {
                    id: 2,
                    name: "rmit",
                    src: rmit
                },
                {
                    id: 3,
                    name: "glossier",
                    src: glossier
                },
                {
                    id: 4,
                    name: "mecca",
                    src: mecca
                },
                {
                    id: 5,
                    name: "adelaide",
                    src: adelaide
                },
            ],
            body: [
                {
                    title: "Build ecommerce site simpler with easy-to-use interface",
                    className: style1.className,
                    itemGroupClassName: style1.itemGroupClassName,
                    itemClassName: style1.itemClassName,
                    itemTextClassName: style1.itemTextClassName,
                    items: [
                        {
                            img: product1item1,
                            title2: "Show product catalogs with",
                            description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
                        },
                        {
                            img: product1item1,
                            title2: "Manage product inventory",
                            description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
                        },
                        {
                            img: product1item1,
                            title2: "Easily manage orders and set shipping options",
                            description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
                        },
                        {
                            img: product1item1,
                            title2: "Notifications for low stock alerts",
                            description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
                        },
                        {
                            img: product1item1,
                            title2: "Connect and integrate with marketplace",
                            description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
                        },
                        {
                            img: product1item1,
                            title2: "Add custom discounts or coupons",
                            description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
                        },
                    ]
                },
                {
                    name: "features",
                    title: "Ecommerce website that built with exciting core features",
                    description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique. Nec in est cursus eu et id viverra scelerisque gravida.",
                    className: style2.className,
                    itemGroupClassName: style2.itemGroupClassName,
                    itemClassName: style2.itemClassName,
                    itemAlternating: style2.itemAlternating,
                    itemTextClassName: style2.itemTextClassName,
                    items: [
                        {
                            img: product1item2,
                            title: "View comprehensive analytics of your store",
                            description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
                        },
                        {
                            img: product1item3,
                            title: "Complete with access management level",
                            description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
                        },
                        {
                            img: product1item4,
                            title: "Automatic orders management",
                            description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
                        },
                    ]
                },
                {
                    title: "Lorem ipsum dolor sit amet",
                    className: style3.className,
                    itemGroupClassName: style3.itemGroupClassName,
                    itemClassName: style3.itemClassName,
                    itemTextClassName: style3.itemTextClassName,
                    items: [
                        {
                            title3: "Lorem ipsum",
                            list: [
                                {
                                    text: "Custom shipping fee",
                                },
                                {
                                    text: "Add product variants",
                                },
                                {
                                    text: "Customizable field for product information",
                                },
                            ]
                        },
                        {
                            title3: "Lorem ipsum",
                            list: [
                                {
                                    text: "Custom shipping fee",
                                },
                                {
                                    text: "Add product variants",
                                },
                                {
                                    text: "Customizable field for product information",
                                },
                            ]
                        },
                        {
                            title3: "Lorem ipsum",
                            list: [
                                {
                                    text: "Custom shipping fee",
                                },
                                {
                                    text: "Add product variants",
                                },
                                {
                                    text: "Customizable field for product information",
                                },
                            ]
                        },
                        {
                            title3: "Lorem ipsum",
                            list: [
                                {
                                    text: "Custom shipping fee",
                                },
                                {
                                    text: "Add product variants",
                                },
                                {
                                    text: "Customizable field for product information",
                                },
                            ]
                        },
                    ]
                },
            ],
            useCases: [
                {
                    icon: HomeIcon,
                    label: "Fashion"
                },
                {
                    icon: HomeIcon,
                    label: "Home and decor"
                },
                {
                    icon: HomeIcon,
                    label: "Food"
                },
                {
                    icon: HomeIcon,
                    label: "Real estate"
                },
                {
                    icon: HomeIcon,
                    label: "Local business"
                },
                {
                    icon: HomeIcon,
                    label: "Restaurant"
                },
            ]
        },
        pricings: [
            {
                id: 1,
                name: "Starter",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 9000000,
                monthlyPrice: 799000,
                annualPrice: 8500000,
                priceDescriptionText: "Save up to 15% annually",
                features: [
                    {
                        id: 1,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 2,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 3,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 4,
                        text: "Lorem ipsum dolor sit amet",
                    },
                ]
            },
            {
                id: 2,
                name: "Essential",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 12000000,
                monthlyPrice: 1199000,
                annualPrice: 12500000,
                priceDescriptionText: "Save up to 15% annually",
                features: [
                    {
                        id: 1,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 2,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 3,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 4,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 5,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 6,
                        text: "Lorem ipsum dolor sit amet",
                    },
                ]
            },
            {
                id: 3,
                name: "Professional",
                description: "Lorem ipsum dolor sit amet",
                corePrice: 18000000,
                monthlyPrice: 1899000,
                annualPrice: 20000000,
                priceDescriptionText: "Save up to 15% annually",
                features: [
                    {
                        id: 1,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 2,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 3,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 4,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 5,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 6,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 7,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 8,
                        text: "Lorem ipsum dolor sit amet",
                    },
                    {
                        id: 9,
                        text: "Lorem ipsum dolor sit amet",
                    },
                ]
            },
        ],
    },
    {
        name: "1-on-1 Video Conferencing Platform",
        description: "Virtual meeting platform designed for 1-on-1 communication, easier than ever.",
        slug: "1-on-1-video-conferencing-platform",
        icon: VideoIcon,
        beta: true,
        // details: {
        //     title: "All in one online store to sell your products",
        //     subtitle: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique. Nec in est cursus eu et id viverra scelerisque gravida. ",
        //     img: showcase4,
        //     brands: [
        //         {
        //             id: 1,
        //             name: "aesop",
        //             src: aesop
        //         },
        //         {
        //             id: 2,
        //             name: "rmit",
        //             src: rmit
        //         },
        //         {
        //             id: 3,
        //             name: "glossier",
        //             src: glossier
        //         },
        //         {
        //             id: 4,
        //             name: "mecca",
        //             src: mecca
        //         },
        //         {
        //             id: 5,
        //             name: "adelaide",
        //             src: adelaide
        //         },
        //     ],
        //     body: [
        //         {
        //             title: "Build ecommerce site simpler with easy-to-use interface",
        //             className: style1.className,
        //             itemGroupClassName: style1.itemGroupClassName,
        //             itemClassName: style1.itemClassName,
        //             itemTextClassName: style1.itemTextClassName,
        //             items: [
        //                 {
        //                     img: product1item1,
        //                     title2: "Show product catalogs with",
        //                     description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
        //                 },
        //                 {
        //                     img: product1item1,
        //                     title2: "Manage product inventory",
        //                     description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
        //                 },
        //                 {
        //                     img: product1item1,
        //                     title2: "Easily manage orders and set shipping options",
        //                     description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
        //                 },
        //                 {
        //                     img: product1item1,
        //                     title2: "Notifications for low stock alerts",
        //                     description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
        //                 },
        //                 {
        //                     img: product1item1,
        //                     title2: "Connect and integrate with marketplace",
        //                     description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
        //                 },
        //                 {
        //                     img: product1item1,
        //                     title2: "Add custom discounts or coupons",
        //                     description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
        //                 },
        //             ]
        //         },
        //         {
        //             name: "features",
        //             title: "Ecommerce website that built with exciting core features",
        //             description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique. Nec in est cursus eu et id viverra scelerisque gravida.",
        //             className: style2.className,
        //             itemGroupClassName: style2.itemGroupClassName,
        //             itemClassName: style2.itemClassName,
        //             itemAlternating: style2.itemAlternating,
        //             itemTextClassName: style2.itemTextClassName,
        //             items: [
        //                 {
        //                     img: product1item2,
        //                     title: "View comprehensive analytics of your store",
        //                     description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
        //                 },
        //                 {
        //                     img: product1item3,
        //                     title: "Complete with access management level",
        //                     description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
        //                 },
        //                 {
        //                     img: product1item4,
        //                     title: "Automatic orders management",
        //                     description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
        //                 },
        //             ]
        //         },
        //         {
        //             title: "Lorem ipsum dolor sit amet",
        //             className: style3.className,
        //             itemGroupClassName: style3.itemGroupClassName,
        //             itemClassName: style3.itemClassName,
        //             itemTextClassName: style3.itemTextClassName,
        //             items: [
        //                 {
        //                     title3: "Lorem ipsum",
        //                     list: [
        //                         {
        //                             text: "Custom shipping fee",
        //                         },
        //                         {
        //                             text: "Add product variants",
        //                         },
        //                         {
        //                             text: "Customizable field for product information",
        //                         },
        //                     ]
        //                 },
        //                 {
        //                     title3: "Lorem ipsum",
        //                     list: [
        //                         {
        //                             text: "Custom shipping fee",
        //                         },
        //                         {
        //                             text: "Add product variants",
        //                         },
        //                         {
        //                             text: "Customizable field for product information",
        //                         },
        //                     ]
        //                 },
        //                 {
        //                     title3: "Lorem ipsum",
        //                     list: [
        //                         {
        //                             text: "Custom shipping fee",
        //                         },
        //                         {
        //                             text: "Add product variants",
        //                         },
        //                         {
        //                             text: "Customizable field for product information",
        //                         },
        //                     ]
        //                 },
        //                 {
        //                     title3: "Lorem ipsum",
        //                     list: [
        //                         {
        //                             text: "Custom shipping fee",
        //                         },
        //                         {
        //                             text: "Add product variants",
        //                         },
        //                         {
        //                             text: "Customizable field for product information",
        //                         },
        //                     ]
        //                 },
        //             ]
        //         },
        //     ],
        //     useCases: [
        //         {
        //             icon: HomeIcon,
        //             label: "Fashion"
        //         },
        //         {
        //             icon: HomeIcon,
        //             label: "Home and decor"
        //         },
        //         {
        //             icon: HomeIcon,
        //             label: "Food"
        //         },
        //         {
        //             icon: HomeIcon,
        //             label: "Real estate"
        //         },
        //         {
        //             icon: HomeIcon,
        //             label: "Local business"
        //         },
        //         {
        //             icon: HomeIcon,
        //             label: "Restaurant"
        //         },
        //     ]
        // },
    },
    {
        name: "Event registration and management",
        description: "Deliver a hassle-free registration experience for your attendees and reduce manual processes to manage your event.",
        slug: "event-registration-and-management",
        icon: CouponIcon,
        beta: true,
    }
    // {
    //     name: "Coupons/Voucher Management System",
    //     description: "Lorem ipsum dolor sit amet consectetur. Vitae lacus quis pharetra faucibus a enim vel risus.",
    //     slug: "coupon-voucher-management-system",
    //     icon: CouponIcon,
    //     details: {
    //         title: "All in one online store to sell your products",
    //         subtitle: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique. Nec in est cursus eu et id viverra scelerisque gravida. ",
    //         img: product1item4,
    //         brands: [
    //             {
    //                 id: 1,
    //                 name: "aesop",
    //                 src: aesop
    //             },
    //             {
    //                 id: 2,
    //                 name: "rmit",
    //                 src: rmit
    //             },
    //             {
    //                 id: 3,
    //                 name: "glossier",
    //                 src: glossier
    //             },
    //             {
    //                 id: 4,
    //                 name: "mecca",
    //                 src: mecca
    //             },
    //             {
    //                 id: 5,
    //                 name: "adelaide",
    //                 src: adelaide
    //             },
    //         ],
    //         body: [
    //             {
    //                 title: "Build ecommerce site simpler with easy-to-use interface",
    //                 className: style1.className,
    //                 itemGroupClassName: style1.itemGroupClassName,
    //                 itemClassName: style1.itemClassName,
    //                 itemTextClassName: style1.itemTextClassName,
    //                 items: [
    //                     {
    //                         img: product1item1,
    //                         title2: "Show product catalogs with",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Manage product inventory",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Easily manage orders and set shipping options",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Notifications for low stock alerts",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Connect and integrate with marketplace",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Add custom discounts or coupons",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                 ]
    //             },
    //             {
    //                 name: "features",
    //                 title: "Ecommerce website that built with exciting core features",
    //                 description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique. Nec in est cursus eu et id viverra scelerisque gravida.",
    //                 className: style2.className,
    //                 itemGroupClassName: style2.itemGroupClassName,
    //                 itemClassName: style2.itemClassName,
    //                 itemAlternating: style2.itemAlternating,
    //                 itemTextClassName: style2.itemTextClassName,
    //                 items: [
    //                     {
    //                         img: product1item2,
    //                         title: "View comprehensive analytics of your store",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item3,
    //                         title: "Complete with access management level",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item4,
    //                         title: "Automatic orders management",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                 ]
    //             },
    //             {
    //                 title: "Lorem ipsum dolor sit amet",
    //                 className: style3.className,
    //                 itemGroupClassName: style3.itemGroupClassName,
    //                 itemClassName: style3.itemClassName,
    //                 itemTextClassName: style3.itemTextClassName,
    //                 items: [
    //                     {
    //                         title3: "Lorem ipsum",
    //                         list: [
    //                             {
    //                                 text: "Custom shipping fee",
    //                             },
    //                             {
    //                                 text: "Add product variants",
    //                             },
    //                             {
    //                                 text: "Customizable field for product information",
    //                             },
    //                         ]
    //                     },
    //                     {
    //                         title3: "Lorem ipsum",
    //                         list: [
    //                             {
    //                                 text: "Custom shipping fee",
    //                             },
    //                             {
    //                                 text: "Add product variants",
    //                             },
    //                             {
    //                                 text: "Customizable field for product information",
    //                             },
    //                         ]
    //                     },
    //                     {
    //                         title3: "Lorem ipsum",
    //                         list: [
    //                             {
    //                                 text: "Custom shipping fee",
    //                             },
    //                             {
    //                                 text: "Add product variants",
    //                             },
    //                             {
    //                                 text: "Customizable field for product information",
    //                             },
    //                         ]
    //                     },
    //                     {
    //                         title3: "Lorem ipsum",
    //                         list: [
    //                             {
    //                                 text: "Custom shipping fee",
    //                             },
    //                             {
    //                                 text: "Add product variants",
    //                             },
    //                             {
    //                                 text: "Customizable field for product information",
    //                             },
    //                         ]
    //                     },
    //                 ]
    //             },
    //         ],
    //         useCases: [
    //             {
    //                 icon: HomeIcon,
    //                 label: "Fashion"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Home and decor"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Food"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Real estate"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Local business"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Restaurant"
    //             },
    //         ]
    //     },
    // },
    // {
    //     name: "Guest Management System",
    //     description: "Lorem ipsum dolor sit amet consectetur. Vitae lacus quis pharetra faucibus a enim vel risus.",
    //     slug: "guest-management-system",
    //     icon: GuestManagementIcon,
    //     details: {
    //         title: "All in one online store to sell your products",
    //         subtitle: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique. Nec in est cursus eu et id viverra scelerisque gravida. ",
    //         img: product1item4,
    //         brands: [
    //             {
    //                 id: 1,
    //                 name: "aesop",
    //                 src: aesop
    //             },
    //             {
    //                 id: 2,
    //                 name: "rmit",
    //                 src: rmit
    //             },
    //             {
    //                 id: 3,
    //                 name: "glossier",
    //                 src: glossier
    //             },
    //             {
    //                 id: 4,
    //                 name: "mecca",
    //                 src: mecca
    //             },
    //             {
    //                 id: 5,
    //                 name: "adelaide",
    //                 src: adelaide
    //             },
    //         ],
    //         body: [
    //             {
    //                 title: "Build ecommerce site simpler with easy-to-use interface",
    //                 className: style1.className,
    //                 itemGroupClassName: style1.itemGroupClassName,
    //                 itemClassName: style1.itemClassName,
    //                 itemTextClassName: style1.itemTextClassName,
    //                 items: [
    //                     {
    //                         img: product1item1,
    //                         title2: "Show product catalogs with",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Manage product inventory",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Easily manage orders and set shipping options",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Notifications for low stock alerts",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Connect and integrate with marketplace",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item1,
    //                         title2: "Add custom discounts or coupons",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                 ]
    //             },
    //             {
    //                 name: "features",
    //                 title: "Ecommerce website that built with exciting core features",
    //                 description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique. Nec in est cursus eu et id viverra scelerisque gravida.",
    //                 className: style2.className,
    //                 itemGroupClassName: style2.itemGroupClassName,
    //                 itemClassName: style2.itemClassName,
    //                 itemAlternating: style2.itemAlternating,
    //                 itemTextClassName: style2.itemTextClassName,
    //                 items: [
    //                     {
    //                         img: product1item2,
    //                         title: "View comprehensive analytics of your store",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item3,
    //                         title: "Complete with access management level",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                     {
    //                         img: product1item4,
    //                         title: "Automatic orders management",
    //                         description: "Lorem ipsum dolor sit amet consectetur. Neque ut facilisis laoreet sapien tristique."
    //                     },
    //                 ]
    //             },
    //             {
    //                 title: "Lorem ipsum dolor sit amet",
    //                 className: style3.className,
    //                 itemGroupClassName: style3.itemGroupClassName,
    //                 itemClassName: style3.itemClassName,
    //                 itemTextClassName: style3.itemTextClassName,
    //                 items: [
    //                     {
    //                         title3: "Lorem ipsum",
    //                         list: [
    //                             {
    //                                 text: "Custom shipping fee",
    //                             },
    //                             {
    //                                 text: "Add product variants",
    //                             },
    //                             {
    //                                 text: "Customizable field for product information",
    //                             },
    //                         ]
    //                     },
    //                     {
    //                         title3: "Lorem ipsum",
    //                         list: [
    //                             {
    //                                 text: "Custom shipping fee",
    //                             },
    //                             {
    //                                 text: "Add product variants",
    //                             },
    //                             {
    //                                 text: "Customizable field for product information",
    //                             },
    //                         ]
    //                     },
    //                     {
    //                         title3: "Lorem ipsum",
    //                         list: [
    //                             {
    //                                 text: "Custom shipping fee",
    //                             },
    //                             {
    //                                 text: "Add product variants",
    //                             },
    //                             {
    //                                 text: "Customizable field for product information",
    //                             },
    //                         ]
    //                     },
    //                     {
    //                         title3: "Lorem ipsum",
    //                         list: [
    //                             {
    //                                 text: "Custom shipping fee",
    //                             },
    //                             {
    //                                 text: "Add product variants",
    //                             },
    //                             {
    //                                 text: "Customizable field for product information",
    //                             },
    //                         ]
    //                     },
    //                 ]
    //             },
    //         ],
    //         useCases: [
    //             {
    //                 icon: HomeIcon,
    //                 label: "Fashion"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Home and decor"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Food"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Real estate"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Local business"
    //             },
    //             {
    //                 icon: HomeIcon,
    //                 label: "Restaurant"
    //             },
    //         ]
    //     },
    // },
]

export const faq = [
    {
        question: "Can I use my own design?",
        answer: "Mapplexon has available template designs to begin with. You may modify a minor changes from the existing template with additional cost, please consult with our team."
    },
    {
        question: "Does Maplexxon software include with hosting?",
        answer: "Yes, hosting will be provided from the selected package."
    },
    {
        question: "Do I get the source code?",
        answer: "Unfortunately, the source code is not transferrable."
    },
    {
        question: "How do I manage the software I subscribed from Maplexxon?",
        answer: "We provide Admin Panel Dashboard as Content Management System (CMS) to manage the platform."
    },
    {
        question: "What can I sell using this platform?",
        answer: "Mapplexon has several solutions for your business depending on your needs. You can build company website landing page, online store (e-commerce), website with booking/scheduling system, 1-on-1 virtual meeting software, and others."
    },
    {
        question: "Is my data safe?",
        answer: "Yes, security is our top priority. Your data is safely stored in a trusted and leading partner cloud storage."
    },
    {
        question: "How to order and subscribe to Maplexxon software?",
        answer: "You can see the product options on our website page first and after schedulling a demo with our team, proceed with the order and payment."
    },
    {
        question: "How do I make payment for subscription?",
        answer: "After filling out the order form, payment can be made via Bank Transfer."
    },
    {
        question: "Is there an affiliate program in Maplexxon?",
        answer: (
            <p>Yes, we do provide a Partner Program, where you can register as our Referral or Reseller. Details can be seen on this page <a className="text-maplexxon-blue" href="/partner-program">www.maplexxon.com/partner-program</a>.</p>
        )
    },
    {
        question: "What kind of support does Maplexxon provide?",
        answer: "We provide basic maintenance service for the software that includes in the monthly maintenance fee. But, worry not, we can offer you a content management service and all-in-one support to ensure your platform is well-managed."
    },
    {
        question: "Can I host my website somewhere else?",
        answer: "Unfortunately, we only support the website hosting with our trusted partners."
    },
    {
        question: "I already have a website, how can I integrate with Maplexxon software?",
        answer: "Of course, you can integrate your current website with our products. Please contact our team for the pricing and integration process."
    },
    {
        question: "How long does it take for my website/platform to go-live?",
        answer: (
            <>
                <p>
                    Once you purchase a subscription with us, it will take about 14-20 working days to go- live *.
                </p>
                <br />
                <p>
                    * Time may vary depends on the website content given from Client
                </p>
            </>
        )
    },
    {
        question: "Can I build a custom website that are not listed in Maplexxon software products?",
        answer: (
            <p>Absolutely, you can consult to our group company - <a className="text-maplexxon-blue" href="https://cloudxier.com">Cloudxier</a> and make custom software to mobile app as you wish for.</p>
        )
    },
    {
        question: "What if I exceeds the limit included in the package plan?",
        answer: "Talk to our team to upgrade and increase the limit. Variety of add-ons and limit upgrades for your package can be purchased at any time."
    },
    {
        question: "Do you provide a dedicated support to set everything up?",
        answer: "Yes, we do! For the Professional plans users, we provide a complimentary dedicated Customer Success Manager to work closely with you to address related queries you may have about the software."
    },
    {
        question: "Are there any hidden fees?",
        answer: "The pricing listed is inclusive with applicable taxes. There are no hidden fees other than set-up fees and subscription fees."
    },
    {
        question: "Can I cancel my subscription?",
        answer: "Yes, you can cancel your account anytime."
    },
    {
        question: "Will my billing pro-rated if I sign up for subscription in the middle of the month?",
        answer: (
            <p>
                Yes, your billing cycle will be calculated from the go-live date. For example: If you sign up on the 15<sup>th</sup> of the month, your next monthly billing cycle will be the 15<sup>th</sup> on the following month.
            </p>
        )
    },
    {
        question: "Is my domain secure?",
        answer: "Every domain that you purchase from Maplexxon includes a 2048-bit SSL certificate and WHOIS privacy."
    },
    {
        question: "I have my own domain already, can I transfer my domain to Maplexxon?",
        answer: "Yes, we do provide domain transfer service. You can connect your website from Maplexxon with domain that you have purchased from third-party provider (such as: GoDaddy, Google, etc)."
    },
    {
        question: "Does Maplexxon support responsive and mobile-friendly sites?",
        answer: "All of our websites/software are responsive, enabling your customers to access the site from tablet or mobile devices."
    },
]