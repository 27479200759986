import React, { useCallback, useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { io } from "socket.io-client";
import { useBeforeUnload } from 'react-router-dom';

import AvatarIcon from 'assets/icons/AvatarIcon'
import CloseIcon from 'assets/icons/CloseIcon'
import SendIcon from 'assets/icons/SendIcon'
// import MultiChatBubbleIcon from 'assets/icons/MultiChatBubbleIcon'
import ExitIcon from 'assets/icons/ExitIcon'

import { useAuth } from 'context/AuthContext'
import { createChatMessage, endChat, getChatRoom, getUserProfile, initUserChatRoom, updateUserName } from 'api'
import { getServerURL } from 'utils/helpers/get-url';
import WhatsappIcon from 'assets/icons/WhatsappIcon';

const socket = io(getServerURL());

function SupportChat() {
    const inputRef = useRef(null)
    const { currentUser, setCurrentUser } = useAuth();
    const chatRoomId = currentUser?.ChatRooms?.[0]?.id

    const endOfMessageRef = useRef(null)
    const [chatRoom, setChatRoom] = useState([])
    const [newMessage, setNewMessage] = useState("")
    const [loadingReply, setLoadingReply] = useState(false)
    const [isChatOpen, setChatOpen] = useState(false)
    const [isEndChatModalOpen, setEndChatModalOpen] = useState(false)
    const [userName, setUserName] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const handleClose = useCallback(() => {
        setChatOpen(false)
    }, [])

    const scrollToBottom = useCallback(() => {
        setTimeout(() => endOfMessageRef?.current?.scrollIntoView({ behavior: "instant" }), 50)
    }, [])

    const handleOpen = useCallback(async () => {
        if (chatRoomId) {
            setLoadingReply(true)
            const _chatRoom = await getChatRoom(chatRoomId);
            setChatRoom(_chatRoom);
            const data = {
                roomId: _chatRoom?.id,
                userId: currentUser?.id,
            }
            socket.emit("join-room", data)
            setLoadingReply(false)
        } else {
            setLoadingReply(true)
            const newChatRoom = await initUserChatRoom();
            const _chatRoom = await getChatRoom(newChatRoom?.id);
            setChatRoom(_chatRoom);
            const userProfile = await getUserProfile();
            setCurrentUser(userProfile)

            const data = {
                roomId: _chatRoom?.id,
                userId: userProfile?.id,
            }
            socket.emit("join-room", data)
            setLoadingReply(false)
        }
        scrollToBottom()
        setChatOpen(true)
    }, [chatRoomId, currentUser?.id, scrollToBottom, setCurrentUser])

    const toggleChat = isChatOpen ? handleClose : handleOpen

    const handleEndChat = async () => {
        await endChat(chatRoomId)
        Cookies.remove('ut')
        setChatOpen(false)
        setEndChatModalOpen(false)
        window.location.reload();
    }

    const handleChange = (e) => {
        if (e?.target?.name === "new_message") {
            setNewMessage(e?.target?.value)
        }
    }

    const fetchChatRoom = useCallback(async () => {
        const _chatRoom = await getChatRoom(chatRoomId);
        setChatRoom(_chatRoom);
        scrollToBottom();
    }, [chatRoomId, scrollToBottom])

    const sendMessage = useCallback(async (newMessage) => {
        await createChatMessage(chatRoomId, currentUser?.id, newMessage)
        socket.emit("new-message", chatRoomId, currentUser?.role_id, newMessage)
    }, [chatRoomId, currentUser?.id, currentUser?.role_id])

    const handleSubmitMessage = async (e) => {
        e.preventDefault();
        if (newMessage) {
            setSubmitting(true)
            setNewMessage("")
            await sendMessage(newMessage)
            await fetchChatRoom()
            setSubmitting(false)
        }
    }

    const handleClickReplyOption = (option) => {
        setLoadingReply(true)
        sendMessage(option.text)
        fetchChatRoom()
        setLoadingReply(false)
    }

    const handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmitMessage(e)
        }
    }

    const handleSaveName = async () => {
        if (userName.length > 1) {
            setSubmitting(true)
            await updateUserName(currentUser?.id, userName)
            await sendMessage(userName)
            await fetchChatRoom()
            setSubmitting(false)
            inputRef.current.focus();
        }
    }

    const handleOnInputNameKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSaveName()
        }
    }

    useEffect(() => {
        socket.on("new-message", (chat_room_id, user_role_id, newMessage) => fetchChatRoom())
        return () => socket.off("new-message")
    }, [fetchChatRoom])

    useEffect(() => {
        socket.on("other-new-message", (chat_room_id, user_role_id, newMessage) => fetchChatRoom())
        return () => socket.off("other-new-message")
    }, [fetchChatRoom])

    useEffect(() => {
        socket.on("bot-new-message", (chat_room_id, user_role_id, newMessages) => fetchChatRoom())
        return () => socket.off("bot-new-message")
    }, [fetchChatRoom])

    useBeforeUnload(
        useCallback(() => {
        }, [])
    );

    const disableInput = chatRoom?.ChatMessages?.[chatRoom?.ChatMessages?.length - 1]?.ChatKeyword?.ChatReplyOptions?.find(option => option?.type === "input") || isSubmitting
    const disableSaveName = userName.length < 2 || isSubmitting

    return (
        <div className='chat-widget fixed z-50 bottom-0 right-0 flex flex-col gap-4 items-end justify-end mr-4 mb-4 md:mr-8 md:mb-8 drop-shadow-md'>
            {isChatOpen && (
                <div className="maplexxon-support-chat-chat-container">
                    <div className="maplexxon-support-chat-chat-inner-container">
                        <div className="bg-black text-white py-4 px-4 rounded-t-2xl">
                            <div className="flex justify-between mb-2">
                                <p className="font-bold">
                                    Maplexxon
                                </p>
                                <div className='flex gap-2 items-center'>
                                    <button className='flex items-center justify-center' onClick={() => setEndChatModalOpen(true)}>
                                        <ExitIcon className="h-4 md:w-4 fill-white" />
                                    </button>
                                    <button className='flex items-center justify-center' onClick={handleClose}>
                                        <CloseIcon className="h-5 md:w-5 fill-white" />
                                    </button>
                                </div>
                            </div>
                            <p className="text-xs">
                                I am your virtual assistant
                            </p>
                        </div>
                        <div className="maplexxon-support-chat-chat-message-container">
                            {chatRoom?.ChatMessages?.map((chat, i) => (
                                chat?.sender_id === currentUser?.id ? (
                                    <div key={i} className="maplexxon-support-chat-user-chat-message-container">
                                        <div className='text-xs bg-maplexxon-blue text-white px-3 py-2 mr-2 rounded-l-lg rounded-br-lg h-fit my-auto'>
                                            {chat.text}
                                        </div>
                                        <div>
                                            <div className='rounded-full bg-black w-8 h-8 flex items-center justify-center'>
                                                <AvatarIcon className="fill-white h-4 w-4" />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={i} className="maplexxon-support-chat-chat-bot-message-container">
                                        <div>
                                            <div className="rounded-full bg-black w-8 h-8 flex items-center justify-center">
                                                <AvatarIcon />
                                            </div>
                                        </div>
                                        <div className="text-xs bg-maplexxon-grey text-black px-3 py-2 ml-2 rounded-r-lg rounded-bl-lg h-fit my-auto">
                                            {chat.text}
                                        </div>
                                    </div>
                                )
                            ))}
                            {loadingReply && (
                                <div className="maplexxon-support-chat-chat-bot-message-container">
                                    <div>
                                        <div className="rounded-full bg-black w-8 h-8 flex items-center justify-center">
                                            <AvatarIcon />
                                        </div>
                                    </div>
                                    <div className="flex gap-1 text-xs bg-maplexxon-grey text-black px-3 py-2 ml-2 rounded-r-lg rounded-bl-lg h-fit my-auto">
                                        <span className="relative flex h-2 w-2">
                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-maplexxon-blue opacity-75"></span>
                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-maplexxon-blue"></span>
                                        </span>
                                        <span className="relative flex h-2 w-2">
                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-maplexxon-blue opacity-75"></span>
                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-maplexxon-blue"></span>
                                        </span>
                                        <span className="relative flex h-2 w-2">
                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-maplexxon-blue opacity-75"></span>
                                            <span className="relative inline-flex rounded-full h-2 w-2 bg-maplexxon-blue"></span>
                                        </span>
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-wrap gap-1">
                                {chatRoom?.ChatMessages?.[chatRoom?.ChatMessages?.length - 1]?.ChatKeyword?.ChatReplyOptions?.map((option, i) => (
                                    option.type === "input" ? (
                                        <div className='w-full flex flex-col p-4 bg-maplexxon-grey-light-2 rounded-xl shadow-md gap-3'>
                                            <div className='flex flex-col gap-2'>
                                                <label htmlFor={option.text} className='text-xs font-semibold'>{option.text}</label>
                                                <input
                                                    id={option.text}
                                                    className="text-left px-3 py-2 rounded-md border border-maplexxon-grey-2 text-xs text-maplexxon-blue-green"
                                                    placeholder={option.text}
                                                    value={userName}
                                                    onChange={(e) => setUserName(e.target.value)}
                                                    onKeyDown={handleOnInputNameKeyDown}
                                                    autoFocus
                                                />
                                            </div>
                                            <button
                                                className={`${disableSaveName ? "bg-maplexxon-grey" : "bg-maplexxon-blue"} text-white px-3 py-2 text-xs rounded-md hover:opacity-90 transition-all`}
                                                onClick={handleSaveName}
                                                disabled={disableSaveName}
                                            >
                                                Send
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            className="text-left px-2 py-1 rounded-3xl border border-maplexxon-grey-2 text-xs text-maplexxon-blue-green"
                                            key={i}
                                            onClick={() => handleClickReplyOption(option)}
                                        >
                                            {option.text}
                                        </button>
                                    )
                                ))}
                            </div>
                            <div ref={endOfMessageRef} />
                        </div>

                        <div className="maplexxon-support-chat-chat-input-container">
                            <form className="maplexxon-support-chat-chat-input-form">
                                <input
                                    ref={inputRef}
                                    className="maplexxon-support-chat-chat-input"
                                    placeholder="Write your message here"
                                    name="new_message"
                                    value={newMessage}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    autoFocus
                                    onKeyDown={handleOnKeyDown}
                                    disabled={disableInput}
                                />
                                <button
                                    className="maplexxon-support-chat-chat-btn-send"
                                    onClick={handleSubmitMessage}
                                    disabled={disableInput}
                                >
                                    <SendIcon />
                                </button>
                            </form>
                        </div>
                    </div>

                    {isEndChatModalOpen && (
                        <div className='fixed top-0 bg-white mt-12 right-0 left-0 mx-4 rounded-md p-4 text-xs flex flex-col items-center gap-2 drop-shadow-md'>
                            <p className='font-bold'>End Chat?</p>
                            <p>All your message will be lost</p>
                            <div className='flex gap-2 w-full'>
                                <button className='border border-black flex-1 text-center py-1' onClick={() => setEndChatModalOpen(false)}>No</button>
                                <button className='bg-black text-white flex-1 text-center py-1' onClick={handleEndChat}>Yes</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <button
                className='bg-maplexxon-blue-light h-10 w-10 md:h-14 md:w-14 flex items-center justify-center rounded-full drop-shadow-md transition-all md:hover:-translate-y-1'
                onClick={() => window.open(`https://wa.me/+6281385898240`, "_blank")}
            >
                <WhatsappIcon className="h-4 w-4 md:h-6 md:w-6 fill-black" />
            </button>
            {/* <button
                className='bg-maplexxon-blue-light h-10 w-10 md:h-14 md:w-14 flex items-center justify-center rounded-full drop-shadow-md transition-all md:hover:-translate-y-1'
                onClick={toggleChat}
            >
                {isChatOpen ? (
                    <CloseIcon className="h-6 w-6 md:h-8 md:w-8 fill-maplexxon-blue" />
                ) : (
                    <MultiChatBubbleIcon className="h-6 w-6 md:h-8 md:w-8 fill-maplexxon-blue" />
                )}
            </button> */}
        </div>
    )
}

export default SupportChat;