import { useRequestDemoModal } from 'context/RequestDemoModalContext';
import React from 'react'

function Discover() {
    const { setRequestDemoModalOpen } = useRequestDemoModal();

    return (
        <div className='py-8 lg:py-20 text-white bg-maplexxon-blue'>
            <div className='max-w-main flex flex-col items-center'>
                <p className="text-center lg:text-left text-xl lg:text-3xl font-bold mb-4 lg:mb-5 maplexxon-animation-translate-start">Discover your way with Mapplexxon</p>
                <p className='text-center lg:text-left text-base lg:text-xl mb-6 lg:mb-10 maplexxon-animation-translate-start'>All in one solutions for your business</p>
                <button className='text-base lg:text-xl bg-maplexxon-purple px-4 py-2 lg:px-6 lg:py-3 maplexxon-animation-scale-start' onClick={() => setRequestDemoModalOpen(true)}>Request for demo</button>
            </div>
        </div>
    )
}

export default Discover