import React, { useState } from 'react'
import moment from 'moment'

import FacebookIcon from 'assets/icons/FacebookIcon'
import InstagramIcon from 'assets/icons/InstagramIcon'
import LinkedInIcon from 'assets/icons/LinkedInIcon'
import TwitterIcon from 'assets/icons/TwitterIcon'
import YouTubeIcon from 'assets/icons/YouTubeIcon'
import maplexxon_logo from "assets/images/maplexxon_logo_black.png"
import cloudxier_logo from "assets/images/cloudxier_logo_black.png"

import { footerItems } from 'utils/constants'
import { setNewsletterOn } from 'api'

function Footer() {
    const [email, setEmail] = useState("");

    const handleSubmit = async () => {
        if (email) {
            await setNewsletterOn(email)
            window.location.reload();
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e)
        }
    }

    return (
        <div className='py-8 lg:py-16'>
            <div className='border-b border-black'>
                <div className='max-w-navigation pb-8 sm:pb-12'>
                    <img className='w-28 lg:w-40 h-auto object-contain mb-2' src={maplexxon_logo} alt="maplexxon" />
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-16'>
                        <p className='mt-4 sm:mt-8 text-base lg:text-xl'>
                            Get insights and be the first one to know about Maplexxon updates, exclusive offers.
                        </p>
                        <div className='flex flex-col gap-2 sm:gap-4'>
                            <label className='uppercase font-bold text-xs md:text-sm'>
                                Subscribe now
                            </label>
                            <div className='flex space-x-2 sm:space-x-4'>
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    placeholder='Enter your email address here'
                                    className='flex-1 border border-maplexxon-blue rounded-lg px-3 py-2 sm:px-4 sm:py-2 text-xs md:text-sm'
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    className='bg-maplexxon-blue uppercase font-bold px-3 py-2 sm:px-6 sm:py-2 rounded-lg text-white text-xs md:text-sm'
                                    onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-navigation pt-8 sm:pt-12 flex flex-col md:flex-row gap-8 lg:gap-0 justify-between mb-6 lg:mb-16'>
                <div className='flex flex-col md:flex-row gap-8 lg:gap-20'>
                    {footerItems.map((group, i) => (
                        <div key={group.id}>
                            <p className='uppercase tracking-widest mb-4 lg:mb-6 font-semibold'>{group.name}</p>
                            <div className='flex flex-col gap-3 lg:gap-4 text-xs lg:text-sm text-maplexxon-grey-2'>
                                {group.routes.map((item, i) => (
                                    <a key={i} href={item.path}>{item.label}</a>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <p className='uppercase tracking-widest mb-6 font-semibold'>Follow Us</p>
                    <div className='flex gap-2 lg:gap-4'>
                        {/* <a href="/">
                            <TwitterIcon className="h-4 lg:h-6" />
                        </a> */}
                        {/* <a href="/">
                            <FacebookIcon className="h-4 lg:h-6" />
                        </a> */}
                        <a target="_blank" href="https://www.instagram.com/maplexxon/" rel="noreferrer">
                            <InstagramIcon className="h-4 lg:h-6" />
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/maplexxon" rel="noreferrer">
                            <LinkedInIcon className="h-4 lg:h-6" />
                        </a>
                        {/* <a href="/">
                            <YouTubeIcon className="h-4 lg:h-6" />
                        </a> */}
                    </div>
                    <div className='mt-10 md:mt-28 xl:mt-36'>
                        <a className='flex flex-row md:justify-end items-center gap-2' target="_blank" href="https://cloudxier.com/" rel="noreferrer">
                            <p className='text-sm text-purple-800'>a Subsidiary Brand of</p>
                            <img className='w-24 lg:w-36 object-contain' src={cloudxier_logo} alt="cloudxier" />
                        </a>
                    </div>
                </div>
            </div>
            <div className='border-t border-x-maplexxon-grey-light pt-6 text-xs'>
                <div className='max-w-navigation flex justify-between flex-col lg:flex-row gap-5 lg:gap-0'>
                    <p>{moment().year()} PT CENDEKIA EDUKASI CITRA TRITUNGGAL |  ALL RIGHTS RESERVED</p>
                    <div className='flex gap-5 flex-col md:flex-row'>
                        <a href="/termsandconditions">Terms and Conditions</a>
                        <a href="/privacypolicy">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer