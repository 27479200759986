import axios from 'axios'
import { getServerURL } from 'utils/helpers/get-url';

const api = axios.create({
    baseURL: getServerURL(),
    withCredentials: true
})

export const getChatRooms = async () => {
    try {
        const response = await api.get('/chat-rooms')
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getChatRoom = async (chat_room_id) => {
    try {
        const response = await api.get(`/chat-rooms/details/${chat_room_id}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const initUserChatRoom = async () => {
    try {
        const response = await api.get(`/chat-rooms/init`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const endChat = async (chat_room_id) => {
    try {
        const response = await api.patch(`/chat-rooms/end/${chat_room_id}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getChatReplyOptionsByKeywordId = async (keyword_id) => {
    try {
        const response = await api.patch(`/chat-rooms/end/${keyword_id}`)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const createChatMessage = async (chat_room_id, sender_id, text) => {
    try {
        const data = {
            chat_room_id,
            sender_id,
            text,
        }

        const response = await api.post('/chat-messages', data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const getUserProfile = async () => {
    try {
        const response = await api.get('/users/profile')
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const updateUserName = async (user_id, user_name) => {
    try {
        const response = await api.patch(`/users/details/name/${user_id}`, {
            name: user_name,
        })
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

export const sendContactUsMail = async (type, full_name, email, phone, subject, message, marketing) => {
    try {
        const response = await api.post(`/email/contact-us`, {
            type,
            full_name,
            email,
            phone,
            subject,
            message,
            marketing
        })
        return response.data
    } catch (error) {
        console.error(error)
    }
}
export const sendPartnerProgramMail = async (type, full_name, email, phone, interest, company, industry, message, marketing) => {
    try {
        const response = await api.post(`/email/contact-us`, {
            type,
            full_name,
            email,
            phone,
            interest,
            company,
            industry,
            message,
            marketing,
        })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const sendRequestDemo = async (
    full_name,
    email,
    phone_country,
    phone,
    company_name,
    company_size,
    department,
    job_position,
    social_media_type,
    social_media_account,
    how_did_you_hear,
    marketing
) => {
    try {
        const response = await api.post(`/email/request-demo`, {
            full_name,
            email,
            phone_country,
            phone,
            company_name,
            company_size,
            department,
            job_position,
            social_media_type,
            social_media_account,
            how_did_you_hear,
            marketing,
        })
        return {
            data: response.data,
            success: true,
        }
    } catch (error) {
        console.error(error)
    }
}

export const setNewsletterOn = async (email) => {
    try {
        const response = await api.post(`/users/newsletter/on`, {
            email
        })
        return response.data
    } catch (error) {
        console.error(error)
    }
}