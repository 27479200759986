import Main from "pages/Main"
import Product from "pages/Product"
import Pricing from "pages/Pricing"
import About from "pages/About"
import Leadership from "pages/Leadership"
import Profile from "pages/Profile"
import ContactUs from "pages/ContactUs"
import PartnerProgram from "pages/PartnerProgram"
import FAQPage from "pages/FAQPage"
import Integrations from "pages/Integrations"
import PrivacyPolicy from "pages/PrivacyPolicy"
import Security from "pages/Security"

import { products } from "./product"
import { team } from "./index"
import TermsAndConditions from "pages/TermsAndConditions"
import PageNotFound from "pages/PageNotFound"

let routes = [
  {
    "path": "/",
    "title": "MAPLEXXON | Home",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <Main />
  },
  {
    "path": "/pricing",
    "title": "MAPLEXXON | Our Pricing",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <Pricing />
  },
  {
    "path": "/about",
    "title": "MAPLEXXON | About Us",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <About />
  },
  {
    "path": "/leadership",
    "title": "MAPLEXXON | Our Leadership",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <Leadership />
  },
  {
    "path": "/contact",
    "title": "MAPLEXXON | Contact Us",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <ContactUs />
  },
  {
    "path": "/partner-program",
    "title": "MAPLEXXON | Partner Program",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <PartnerProgram />
  },
  {
    "path": "/faq",
    "title": "MAPLEXXON | FAQ",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <FAQPage />
  },
  {
    "path": "/integrations",
    "title": "MAPLEXXON | Integrations",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <Integrations />
  },
  {
    "path": "/privacypolicy",
    "title": "MAPLEXXON | Privacy Policy",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <PrivacyPolicy />
  },
  {
    "path": "/security",
    "title": "MAPLEXXON | Security",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <Security />
  },
  {
    "path": "/termsandconditions",
    "title": "MAPLEXXON | Terms and Conditions",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <TermsAndConditions />
  },
  {
    "path": "*",
    "title": "MAPLEXXON | Page Not Found",
    "title_identifier": null,
    "description": "Software solutions for your business",
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <PageNotFound />
  },
]

// Append product pages to routes
products.forEach((product) => {
  const productName = product && product.name
  const productSlug = product && product.slug
  const productDescription = product && product.description ? product.description : 'Software solutions for your business'
  const route = {
    "path": `/product/${productSlug}`,
    "title": `MAPLEXXON | ${productName}`,
    "title_identifier": null,
    "description": productDescription,
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <Product />
  }
  routes.push(route)
})

// Append profile pages to routes
team.forEach((item) => {
  const teamName = item && item.name
  const teamSlug = item && item.slug
  const teamDescription = item && item.role ? item.role : 'Software solutions for your business'
  const route = {
    "path": `/profile/${teamSlug}`,
    "title": `MAPLEXXON | ${teamName}`,
    "title_identifier": null,
    "description": teamDescription,
    "description_identifier": null,
    "params": null,
    "query": null,
    "endpoint_url": null,
    "element": <Profile />
  }
  routes.push(route)
})

console.log('router >>>', routes)

export default routes