import React from 'react'

function DataBackupIcon({
    className = "h-14 w-14 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5385 0C12.9405 0 8.70492 0.844769 5.56554 2.27123C2.55769 3.63923 0 5.82569 0 8.76923C0 11.7128 2.55769 13.8992 5.56554 15.2672C8.70492 16.6937 12.9405 17.5385 17.5385 17.5385C22.1365 17.5385 26.372 16.6937 29.5114 15.2672C32.5192 13.8992 35.0769 11.7128 35.0769 8.76923C35.0769 5.82569 32.5192 3.63923 29.5114 2.27123C26.372 0.844769 22.1365 0 17.5385 0Z" />
            <path d="M5.56554 24.0364C2.55769 22.6684 0 20.4819 0 17.5384V15.0859C1.33585 16.3487 2.93477 17.2812 4.35538 17.9272C7.95662 19.5641 12.6218 20.4615 17.5385 20.4615C22.4551 20.4615 27.1203 19.5641 30.7215 17.9272C32.1422 17.2841 33.7411 16.3487 35.0769 15.0859V17.5384C35.0769 18.7778 34.6238 19.8827 33.8755 20.8502C32.5729 20.5262 31.2283 20.403 29.8885 20.4849C28.7297 20.3959 27.5648 20.539 26.4621 20.9059C25.3593 21.2728 24.3409 21.8561 23.4665 22.6216C22.0196 23.5752 20.7741 24.8037 19.8009 26.2375C19.0477 26.2846 18.2932 26.308 17.5385 26.3076C12.9405 26.3076 8.70492 25.4629 5.56554 24.0364ZM17.5385 37.9999C12.6218 37.9999 7.95662 37.1026 4.35538 35.4656C2.93477 34.8226 1.33585 33.8872 0 32.6244V35.0769C0 38.0204 2.55769 40.2069 5.56554 41.5749C8.70492 43.0013 12.9405 43.8461 17.5385 43.8461C17.8015 43.8461 18.0588 43.8461 18.316 43.8373C17.8051 42.9512 17.5369 41.9459 17.5385 40.923V37.9999ZM17.5385 33.6153C17.5385 33.9076 17.5472 34.2029 17.5677 34.4922C17.5477 34.6865 17.5379 34.8816 17.5385 35.0769C12.9405 35.0769 8.70492 34.2321 5.56554 32.8056C2.55769 31.4376 0 29.2512 0 26.3076V23.8552C1.33585 25.1179 2.93477 26.0504 4.35538 26.6964C7.95662 28.3333 12.6218 29.2307 17.5385 29.2307C17.7898 29.2307 18.0412 29.2307 18.2897 29.2249C17.7915 30.635 17.5375 32.1198 17.5385 33.6153Z" />
            <path d="M20.4614 35.0771C20.4614 34.3018 20.7694 33.5583 21.3176 33.0101C21.8658 32.462 22.6093 32.154 23.3845 32.154V29.2309C23.3845 27.6804 24.0004 26.1934 25.0968 25.0971C26.1932 24.0007 27.6802 23.3848 29.2307 23.3848C30.7812 23.3848 32.2681 24.0007 33.3645 25.0971C34.4609 26.1934 35.0768 27.6804 35.0768 29.2309V32.154C35.8521 32.154 36.5956 32.462 37.1437 33.0101C37.6919 33.5583 37.9999 34.3018 37.9999 35.0771V40.9232C37.9999 41.6985 37.6919 42.442 37.1437 42.9902C36.5956 43.5383 35.8521 43.8463 35.0768 43.8463H23.3845C22.6093 43.8463 21.8658 43.5383 21.3176 42.9902C20.7694 42.442 20.4614 41.6985 20.4614 40.9232V35.0771ZM29.2307 26.3078C28.4554 26.3078 27.7119 26.6158 27.1637 27.164C26.6155 27.7122 26.3076 28.4557 26.3076 29.2309V32.154H32.1537V29.2309C32.1537 28.4557 31.8458 27.7122 31.2976 27.164C30.7494 26.6158 30.0059 26.3078 29.2307 26.3078Z" />
        </svg>
    )
}

export default DataBackupIcon