import React, { useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { sendPartnerProgramMail } from 'api'
import ReCAPTCHA from "react-google-recaptcha";

import item1 from '../assets/images/products/product1/item1.png'
import manWebDesigner from 'assets/images/man-web-designer.png'
import CheckDecoratedIcon from 'assets/icons/CheckDecoratedIcon'
import CloseIcon from 'assets/icons/CloseIcon';
import { benefits, referral, reseller } from 'utils/constants/program';
import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowDownIcon';

import dedicatedSupportIcon from "assets/images/partner/dedicated-support.png"
import betaReleaseIcon from "assets/images/partner/beta-release.png"
import extraIncomeIcon from "assets/images/partner/extra-income.png"
import SelectInput from 'components/Input/SelectInput';

function PartnerProgram() {
    const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
    const [captchaError, setCaptchaError] = useState(false)

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    } = useFormik({
        initialValues: {
            full_name: "",
            email: "",
            phone: "",
            interest: "",
            company: "",
            industry: "",
            message: "",
            marketing: false,
        },
        validationSchema: Yup.object({
            full_name: Yup.string().required('Full name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            phone: Yup.number().required('Phone number is required'),
            interest: Yup.string().required('Interest is required'),
            company: Yup.string().required('Company/organization name is required'),
            industry: Yup.string().required('Industry is required'),
            message: Yup.string().required('Message is required'),
            marketing: Yup.boolean(),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            if (isCaptchaCorrect) {
                setSubmitting(true)
                await sendPartnerProgramMail("partner-program", values.full_name, values.email, values.phone, values.interest, values.company, values.industry, values.message, values?.marketing)
                setSubmitting(false)
                window.location.reload();
            } else {
                setCaptchaError(true)
            }
        },
    })

    const onChangeCaptcha = (value) => {
        setCaptchaError(false)
        if (value) {
            setCaptchaCorrect(true);
        } else {
            setCaptchaCorrect(false);
        }
    };

    return (
        <div>
            <section>
                <div className='max-w-main py-8 lg:py-16 space-y-4 text-center'>
                    <p className='text-xl lg:text-3xl font-bold mb-6 lg:mb-12 text-maplexxon-purple maplexxon-animation-translate-start'>
                        Expand and grow your business network with Maplexxon.
                    </p>
                    <p className='text-base lg:text-xl maplexxon-animation-translate-start'>
                        This program benefits you to join our reseller or referral partners and start earning more to drive client success using Maplexxon technology.
                    </p>
                </div>
            </section>
            <section className='bg-maplexxon-grey-light'>
                <div className='max-w-main py-8 lg:py-16 space-y-4 text-center'>
                    <p className='text-xl lg:text-3xl font-bold mb-6 lg:mb-12 maplexxon-animation-translate-start'>
                        Why you should join us?
                    </p>
                    <div className='grid grid-cols-1 mx-4 sm:mx-0 sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-4 md:gap-12'>
                        <div className='flex flex-col gap-2 sm:gap-4'>
                            <img className="maplexxon-animation-scale-start" src={dedicatedSupportIcon} alt="dedicated-support" />
                            <p className='text-lg lg:text-2xl font-bold text-maplexxon-blue maplexxon-animation-translate-start'>Dedicated support</p>
                            <p className='text-sm lg:text-base tracking-wide mb-4 maplexxon-animation-translate-start'>
                                We're committed to your success. By joining our program, a dedicated support from our team of experts will provide you with technical assistance, sales training, and ongoing guidance to ensure a smooth journey as a reseller or referral partner.
                            </p>
                        </div>
                        <div className='flex flex-col gap-2 sm:gap-4'>
                            <img className="maplexxon-animation-scale-start" src={betaReleaseIcon} alt="beta-release" />
                            <p className='text-lg lg:text-2xl font-bold text-maplexxon-blue maplexxon-animation-translate-start'>Access to beta release for our new features or products</p>
                            <p className='text-sm lg:text-base tracking-wide mb-4 maplexxon-animation-translate-start'>
                                You'll have early access to our beta releases, allowing you to stay ahead of the curve and provide your customers with the latest advancements in Maplexxon software products.
                            </p>
                        </div>
                        <div className='flex flex-col gap-2 sm:gap-4'>
                            <img className="maplexxon-animation-scale-start" src={extraIncomeIcon} alt="extra-income" />
                            <p className='text-lg lg:text-2xl font-bold text-maplexxon-blue maplexxon-animation-translate-start'>Earn extra income from the partner commissions</p>
                            <p className='text-sm lg:text-base tracking-wide mb-4 maplexxon-animation-translate-start'>
                                Anybody can become our partners, whether you are an individual, freelancers, agencies, bloggers or business owners. You are welcome to join us and start gaining more revenue.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-maplexxon-grey-light'>
                <div className='max-w-main py-8 lg:py-16 space-y-4 sm:space-y-8 text-center'>
                    <p className='text-lg lg:text-2xl font-bold maplexxon-animation-translate-start'>Partner's benefit</p>

                    <table className='max-w-2xl mx-auto w-full border-spacing-0 border-separate table-auto rounded-xl border border-maplexxon-blue maplexxon-animation-translate-start'>
                        <thead>
                            <tr className='rounded-t-xl'>
                                <th className='bg-maplexxon-grey-5 font-medium rounded-tl-xl border-r border-r-maplexxon-blue text-sm lg:text-lg text-left pt-3 pb-1 px-2 md:pt-6 md:pb-2 md:px-4'>
                                    Benefit
                                </th>
                                <th className='bg-maplexxon-grey-5 font-medium rounded-tl-xl border-r border-r-maplexxon-blue text-sm lg:text-lg text-center pt-3 pb-1 px-2 md:pt-6 md:pb-2 md:px-4'>
                                    Referral
                                </th>
                                <th className='bg-maplexxon-grey-5 font-medium rounded-tr-xl text-sm lg:text-lg text-center pt-3 pb-1 px-2 md:pt-6 md:pb-2 md:pl-4'>
                                    Reseller
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {benefits.map((benefit, i) => (
                                <tr key={i}>
                                    <td className={`text-left text-xs lg:text-sm py-2 px-2 md:py-4 md:pl-4 ${i === benefits?.length - 1 ? "" : "border-b"} border-r border-r-maplexxon-blue`}>{benefit.benefit}</td>
                                    <td className={`text-left text-xs lg:text-sm py-2 px-2 md:py-4 md:px-4 ${i === benefits?.length - 1 ? "" : "border-b "} border-r border-r-maplexxon-blue`}>
                                        {benefit.referral ? (
                                            <CheckDecoratedIcon className='fill-black h-4 sm:h-5 w-4 sm:w-5 mx-auto' />
                                        ) : (
                                            <CloseIcon className='fill-black h-4 sm:h-5 w-4 sm:w-5 mx-auto' />
                                        )}
                                    </td>
                                    <td className={`text-left text-xs lg:text-sm py-2 px-2 md:py-4 md:px-4 ${i === benefits?.length - 1 ? "" : "border-b"}`}>
                                        {benefit.reseller ? (
                                            <CheckDecoratedIcon className='fill-black h-4 sm:h-5 w-4 sm:w-5 mx-auto' />
                                        ) : (
                                            <CloseIcon className='fill-black h-4 sm:h-5 w-4 sm:w-5 mx-auto' />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <section className='bg-maplexxon-blue-light-3'>
                <div className='max-w-main py-4 lg:py-12 space-y-4 sm:space-y-2'>
                    <p className='text-lg lg:text-2xl font-bold maplexxon-animation-translate-start text-center'>Referral program</p>
                    <div className='flex flex-col sm:flex-row items-center gap-4 sm:gap-8'>
                        <img src={manWebDesigner} alt="man-web-designer" className='w-8/12 sm:w-6/12 maplexxon-animation-scale-start' />
                        <p className='text-sm lg:text-lg maplexxon-animation-translate-start w-full sm:w-6/12 text-center sm:text-left'>{referral.description}</p>
                    </div>
                    <p className='text-lg lg:text-2xl font-bold maplexxon-animation-translate-start text-center'>How it works</p>
                    <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-10'>
                        {referral.howItWorks.map(item => (
                            <div className='flex flex-col items-center text-center sm:items-start sm:text-left space-y-2 sm:space-y-4 maplexxon-animation-translate-start' key={item?.id}>
                                <div className='text-lg lg:text-2xl font-bold w-9 h-9 sm:w-12 sm:h-12 rounded-full bg-maplexxon-grey flex items-center justify-center'>{item?.id}</div>
                                <div className='text-lg lg:text-2xl font-bold'>{item?.title}</div>
                                <div className='text-sm lg:text-base tracking-wide '>{item?.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className='bg-maplexxon-mint-light'>
                <div className='max-w-main py-4 lg:py-12 space-y-4 sm:space-y-2'>
                    <p className='text-lg lg:text-2xl font-bold maplexxon-animation-translate-start text-center'>Reseller program</p>
                    <div className='flex flex-col sm:flex-row items-center gap-4 sm:gap-8'>
                        <img src={manWebDesigner} alt="man-web-designer" className='w-8/12 sm:w-6/12 maplexxon-animation-scale-start' />
                        <p className='text-sm lg:text-lg maplexxon-animation-translate-start w-full sm:w-6/12 text-center sm:text-left'>{reseller.description}</p>
                    </div>
                    <p className='text-lg lg:text-2xl font-bold maplexxon-animation-translate-start text-center'>How it works</p>
                    <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-10'>
                        {reseller.howItWorks.map(item => (
                            <div className='flex flex-col items-center text-center sm:items-start sm:text-left space-y-2 sm:space-y-4 maplexxon-animation-translate-start' key={item?.id}>
                                <div className='text-lg lg:text-2xl font-bold w-9 h-9 sm:w-12 sm:h-12 rounded-full bg-maplexxon-grey flex items-center justify-center'>{item?.id}</div>
                                <div className='text-lg lg:text-2xl font-bold'>{item?.title}</div>
                                <div className='text-sm lg:text-base tracking-wide '>{item?.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className='bg-maplexxon-grey-light'>
                <div className='max-w-main py-8 lg:py-16 space-y-4 sm:space-y-8'>
                    <p className='text-lg lg:text-2xl font-bold text-center maplexxon-animation-translate-start'>Register to become our partner</p>
                    <div className='flex flex-col gap-4 md:gap-6 max-w-lg mx-auto'>
                        <div className='flex flex-col gap-2'>
                            <label htmlFor="full_name" className='text-xs lg:text-sm font-bold uppercase'>Full name</label>
                            <input
                                id="full_name"
                                name="full_name"
                                className="text-left px-3 py-2 sm:py-3 rounded-lg border border-maplexxon-grey-2 text-xs lg:text-sm text-maplexxon-blue-green"
                                placeholder="Enter your full name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.full_name}
                                autoComplete="off"
                            />
                            {errors?.full_name && touched?.full_name && (
                                <p className='text-xs text-red-500'>{errors?.full_name}</p>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label htmlFor="email" className='text-xs lg:text-sm font-bold uppercase'>Email address</label>
                            <input
                                id="email"
                                name="email"
                                className="text-left px-3 py-2 sm:py-3 rounded-lg border border-maplexxon-grey-2 text-xs lg:text-sm text-maplexxon-blue-green"
                                placeholder="Enter your email address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                autoComplete="off"
                            />
                            {errors?.email && touched?.email && (
                                <p className='text-xs text-red-500'>{errors?.email}</p>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label htmlFor="phone_number" className='text-xs lg:text-sm font-bold uppercase'>Phone number</label>
                            <input
                                id="phone"
                                name="phone"
                                type='number'
                                className="text-left px-3 py-2 sm:py-3 rounded-lg border border-maplexxon-grey-2 text-xs lg:text-sm text-maplexxon-blue-green"
                                placeholder="Enter your phone number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                autoComplete="off"
                            />
                            {errors?.phone && touched?.phone && (
                                <p className='text-xs text-red-500'>{errors?.phone}</p>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label htmlFor="interest" className='text-xs lg:text-sm font-bold uppercase'>Interested in</label>
                            <SelectInput
                                name="interest"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.interest}
                                className={`text-xs lg:text-sm px-2 rounded-lg ${values?.interest ? "text-maplexxon-blue-green" : "text-black/40"} bg-white border-maplexxon-grey-2`}
                                options={[
                                    {
                                        label: "Select what you interested in",
                                        value: ""
                                    },
                                    {
                                        label: "Referral program",
                                        value: "referral"
                                    },
                                    {
                                        label: "Reseller program",
                                        value: "reseller"
                                    },
                                ]}
                            />
                            {errors?.interest && touched?.interest && (
                                <p className='text-xs text-red-500'>{errors?.interest}</p>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label htmlFor="company" className='text-xs lg:text-sm font-bold uppercase'>Company/organization name</label>
                            <input
                                id="company"
                                name="company"
                                className="text-left px-3 py-2 sm:py-3 rounded-lg border border-maplexxon-grey-2 text-xs lg:text-sm text-maplexxon-blue-green"
                                placeholder="Enter your company or organization name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.company}
                                autoComplete="off"
                            />
                            {errors?.company && touched?.company && (
                                <p className='text-xs text-red-500'>{errors?.company}</p>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label htmlFor="industry" className='text-xs lg:text-sm font-bold uppercase'>Industry</label>
                            <input
                                id="industry"
                                name="industry"
                                className="text-left px-3 py-2 sm:py-3 rounded-lg border border-maplexxon-grey-2 text-xs lg:text-sm text-maplexxon-blue-green"
                                placeholder="Enter your industry"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.industry}
                                autoComplete="off"
                            />
                            {errors?.industry && touched?.industry && (
                                <p className='text-xs text-red-500'>{errors?.industry}</p>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label htmlFor="message" className='text-xs lg:text-sm font-bold uppercase'>Your message</label>
                            <textarea
                                id="message"
                                name="message"
                                className="text-left px-3 py-2 sm:py-3 rounded-lg border border-maplexxon-grey-2 text-xs lg:text-sm text-maplexxon-blue-green"
                                placeholder="Enter your message here"
                                rows={8}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                                autoComplete="off"
                            />
                            {errors?.message && touched?.message && (
                                <p className='text-xs text-red-500'>{errors?.message}</p>
                            )}
                        </div>
                        <div className={`w-min scale-90 sm:scale-100 origin-left ${captchaError ? "border border-red-500" : ""}`}>
                            <ReCAPTCHA
                                sitekey="6LehV8goAAAAACpKWwgn14wFJ2tlxt9dtzDzSVfu"
                                onChange={onChangeCaptcha}
                            />
                        </div>
                        {captchaError && (
                            <p className='text-xs text-red-500'>
                                Please check the captcha box before you proceed
                            </p>
                        )}
                        <div className='space-y-4'>
                            <p className='text-xs lg:text-sm'>By registering, you confirm that you agree to the processing of your personal data by Maplexxon as described in the <a className="underline" href='/privacypolicy' target='_blank' rel='noreferrer'>Privacy Policy</a></p>
                            <div className='flex items-start gap-2'>
                                <input
                                    type='checkbox'
                                    id="marketing"
                                    name="marketing"
                                    value={values.marketing}
                                    onChange={handleChange}
                                />
                                <label htmlFor="marketing" className='text-xs lg:text-sm'>
                                    Yes, I would like to receive marketing communications about the Maplexxon - PT Cendekia Edukasi Citra Tritunggal family of companies' products, services, and events. I can unsubscribe at any time.
                                </label>
                            </div>
                        </div>
                        <button disabled={isSubmitting} onClick={handleSubmit} className='text-sm lg:text-base bg-maplexxon-blue text-white p-2 rounded-lg'>Submit</button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PartnerProgram