import React from 'react'

function DataResidencyIcon({
    className = "h-14 w-14 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 51 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.85 20.5417L44.8375 10.4833L42.8542 8.5L34.85 16.5042L30.8125 12.4667L28.8292 14.5208L34.85 20.5417ZM0 48.1667V42.5H34V48.1667H0ZM36.8333 28.3333C32.9139 28.3333 29.5724 26.9516 26.809 24.1882C24.0456 21.4247 22.6648 18.0842 22.6667 14.1667C22.6667 10.2472 24.0484 6.90578 26.8118 4.14233C29.5753 1.37889 32.9158 -0.00188695 36.8333 1.93534e-06C40.7528 1.93534e-06 44.0942 1.38172 46.8577 4.14517C49.6211 6.90861 51.0019 10.2491 51 14.1667C51 18.0861 49.6183 21.4276 46.8548 24.191C44.0914 26.9544 40.7509 28.3352 36.8333 28.3333ZM0 25.5V19.8333H17.85C18.1806 20.8722 18.5583 21.8639 18.9833 22.8083C19.4083 23.7528 19.9278 24.65 20.5417 25.5H0ZM0 36.8333V31.1667H26.6333C27.7194 31.8278 28.8764 32.3831 30.1042 32.8327C31.3319 33.2822 32.6306 33.6005 34 33.7875V36.8333H0Z" />
        </svg>
    )
}

export default DataResidencyIcon