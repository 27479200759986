const {
  NODE_ENV,
  CLIENT_DEV_URL,
  CLIENT_STAGING_URL,
  CLIENT_PROD_URL,
  SERVER_DEV_URL,
  SERVER_STAGING_URL,
  SERVER_PROD_URL
} = require('../../config')

function getClientURL () {
  const env = NODE_ENV || 'development';
  let url = CLIENT_DEV_URL;
  switch(env.trim()) {
    case 'development':
      url = CLIENT_DEV_URL;
      break;
    case 'staging':
      url = CLIENT_STAGING_URL;
      break;
    case 'production':
      url = CLIENT_PROD_URL;
      break;
    default:
      url = CLIENT_DEV_URL;
  }
  return url;
};

function getServerURL () {
  const env = NODE_ENV || 'development';
  let url = SERVER_DEV_URL;
  switch(env.trim()) {
    case 'development':
      url = SERVER_DEV_URL;
      break;
    case 'staging':
      url = SERVER_STAGING_URL;
      break;
    case 'production':
      url = SERVER_PROD_URL;
      break;
    default:
      url = CLIENT_DEV_URL;
  }
  return url;
};

module.exports = {
  getClientURL,
  getServerURL
}