export const addOns = [
    {
        id: 1,
        name: "Content management",
        description: "Offering you the extra support you need to keep your website fresh, relevant, and captivating.",
        description2: "Offering you the extra service to save your time to ensure your website is well-managed. So, you can focus on your core business activities while we handle the time-consuming task of content management and input.",
        price: 2000000,
        type: "Per month"
    },
    {
        id: 2,
        name: "Strategic Sustainability storytelling",
        description: "Elevate your brand's sustainability narrative with expert website copywriting services.",
        description2: (
            <>
                <p>This add ons is designed to provide you with the extra edge you need to craft a compelling Sustainability storytelling for your company that elevates your Brand as well as helping you communicate your dedication to responsible practices, one compelling word at a time.</p><br />
                <p>Our team of experts can boost up your sustainability efforts into compelling stories that showcase your commitment to responsible practices.</p>
            </>
        ),
        price: 6500000,
        type: "One time payment"
    },
    {
        id: 3,
        name: "Minor design changes",
        description: "Enhance your website's visual appeal with custom graphics and elements that reflect your brand's identity and message.",
        description2: "This service is here to help you take your website from good to exceptional by tailoring it to your unique visuals that fits in your brand's tone and voices. We'll refine the layout of your our website, making it more intuitive for users to navigate and find the information they need.",
        price: 3000000,
        type: "One time payment"
    },
    {
        id: 4,
        name: "Additional user access account",
        description: "Let us help you establish an efficient environment that simplifies user management and enhances your website's capabilities.",
        description2: "This service is designed to provide you with the capabilities needed to optimize your admin panel's functionality. By seamlessly adding user accounts, you can enhance security, improve collaboration, and take control of user privileges with ease.",
        price: 99000,
        type: "Per user per month"
    },
    {
        id: 5,
        name: "Custom invoice template",
        description: "Tailored invoice templates for a professional touch.",
        description2: (
            <>
                <p>Providing you a custom invoice templates that incorporate your company's logo, color scheme, and brand identity, ensuring a consistent and professional look.</p><br />
                <p>Streamline your invoicing process with templates that are easy to use and adapt to various clients and projects.</p>
            </>
        ),
        price: 2000000,
        type: "One time payment"
    },
    {
        id: 6,
        name: "Upgrade with booking system",
        description: "Seamless booking system integration for enhanced user experience.",
        description2: (
            <>
                <p>We provide integration to our booking system that suits your business needs, ensuring it aligns with your specific service offerings and scheduling requirements.</p><br />
                <p>This includes a secure payment processing, enabling users to make reservations and payments seamlessly.</p>
            </>
        ),
        multiPrice: true,
        price: [3500000, 399000],
        type: ["One time payment", "Per month"]
    },
    {
        id: 7,
        name: "Online video conferencing integrations (with GoogleMeet, Zoom)",
        description: "Video conferencing integration for enhanced online meetings.",
        description2: "We provide integration to your website with popular video conferencing tools such as Google Meet and Zoom to allow users to schedule and manage meetings directly from your website, streamlining the process and reducing friction.",
        price: 3000000,
        type: "One time payment"
    },
]

export const reviews = [
    {
        name: "John Doe",
        company: "XYZ Corp",
        role: "Vice President Engineering",
        text: "Lorem ipsum dolor sit amet consectetur. Vitae lacus quis pharetra faucibus a enim vel risus pharetra faucibus a enim vel risus."
    },
    {
        name: "John Doe",
        company: "XYZ Corp",
        role: "Vice President Engineering",
        text: "Lorem ipsum dolor sit amet consectetur. Vitae lacus quis pharetra faucibus a enim vel risus pharetra faucibus a enim vel risus."
    },
    {
        name: "John Doe",
        company: "XYZ Corp",
        role: "Vice President Engineering",
        text: "Lorem ipsum dolor sit amet consectetur. Vitae lacus quis pharetra faucibus a enim vel risus pharetra faucibus a enim vel risus."
    },
]