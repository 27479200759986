export const benefits = [
    {
        benefit: "Revenue share",
        referral: true,
        reseller: false,
    },
    {
        benefit: "Sales kit",
        referral: true,
        reseller: true,
    },
    {
        benefit: "Product knowledge",
        referral: true,
        reseller: true,
    },
    {
        benefit: "Feature training",
        referral: false,
        reseller: true,
    },
    {
        benefit: "Technical support",
        referral: false,
        reseller: true,
    },
    {
        benefit: "Exclusive discount",
        referral: false,
        reseller: true,
    },
    {
        benefit: "Partner badge",
        referral: true,
        reseller: true,
    },
]

export const referral = {
    description: "Promote, earn and grow by simply sharing referral links to your audience. We are welcoming you to become a Maplexxon affiliate.",
    howItWorks: [
        {
            id: 1,
            title: "Apply to join referral program",
            description: "Before joining our referral program, you can apply and fill in the affiliate form"
        },
        {
            id: 2,
            title: "Get the link and start earning",
            description: "Once approved and reviewed, start to promote Maplexxon by sharing the link using our promo materials"
        },
        {
            id: 3,
            title: "Earn revenue and get the payment",
            description: "You can track the clicks and earnings in the partner portal"
        },
    ]
}

export const reseller = {
    description: "Join our Reseller Program and be a key player in transforming businesses with Maplexxon. As a reseller, you'll gain access to a cutting-edge software suite that opens doors for growth, efficiency, and success.",
    howItWorks: [
        {
            id: 1,
            title: "Apply to join reseller program",
            description: "Enter your basic details and apply to become reseller."
        },
        {
            id: 2,
            title: "Get in touch with our team support",
            description: "Once approved and registered, you will receive notification from us to get in touch with our team support."
        },
        {
            id: 3,
            title: "Start gaining revenue stream",
            description: "Gain revenue stream and expand your network of clients powered by Maplexxon technology."
        },
    ]
}