import { createContext, useContext, useState } from 'react'

export const RequestDemoContext = createContext();
export const RequestDemoUpdateContext = createContext();

export const useRequestDemoModal = () => {
    return {
        isRequestDemoModalOpen: useContext(RequestDemoContext),
        setRequestDemoModalOpen: useContext(RequestDemoUpdateContext),
    }
}

export const RequestDemoProvider = ({ children }) => {
    const [isRequestDemoModalOpen, setRequestDemoModalOpen] = useState(false)

    return (
        <RequestDemoContext.Provider value={isRequestDemoModalOpen}>
            <RequestDemoUpdateContext.Provider value={setRequestDemoModalOpen}>
                {children}
            </RequestDemoUpdateContext.Provider>
        </RequestDemoContext.Provider>
    )
}