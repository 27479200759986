import React from 'react'

function CouponIcon({
    className,
    width = "64",
    height = "57",
    fill = "#8000CF",
}) {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 64 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 20.5833V3.16667C0 2.32682 0.33363 1.52136 0.927495 0.927495C1.52136 0.33363 2.32682 0 3.16667 0H60.1667C61.0065 0 61.812 0.33363 62.4059 0.927495C62.9997 1.52136 63.3334 2.32682 63.3334 3.16667V20.5833C61.2337 20.5833 59.2201 21.4174 57.7354 22.9021C56.2508 24.3867 55.4167 26.4004 55.4167 28.5C55.4167 30.5996 56.2508 32.6133 57.7354 34.0979C59.2201 35.5826 61.2337 36.4167 63.3334 36.4167V53.8334C63.3334 54.6732 62.9997 55.4787 62.4059 56.0725C61.812 56.6664 61.0065 57 60.1667 57H3.16667C2.32682 57 1.52136 56.6664 0.927495 56.0725C0.33363 55.4787 0 54.6732 0 53.8334V36.4167C2.09963 36.4167 4.11327 35.5826 5.59793 34.0979C7.08259 32.6133 7.91667 30.5996 7.91667 28.5C7.91667 26.4004 7.08259 24.3867 5.59793 22.9021C4.11327 21.4174 2.09963 20.5833 0 20.5833ZM6.33334 15.732C8.71216 16.9109 10.7143 18.731 12.1141 20.987C13.5139 23.2429 14.2556 25.8451 14.2556 28.5C14.2556 31.155 13.5139 33.7571 12.1141 36.0131C10.7143 38.269 8.71216 40.0891 6.33334 41.268V50.6667H57V41.268C54.6212 40.0891 52.619 38.269 51.2193 36.0131C49.8195 33.7571 49.0778 31.155 49.0778 28.5C49.0778 25.8451 49.8195 23.2429 51.2193 20.987C52.619 18.731 54.6212 16.9109 57 15.732V6.33334H6.33334V15.732ZM22.1667 19H41.1667V25.3333H22.1667V19ZM22.1667 31.6667H41.1667V38H22.1667V31.6667Z" fill={fill} />
        </svg>
    )
}

export default CouponIcon