import FAQ from 'components/FAQ/FAQ'
import React from 'react'

function FAQPage() {
    return (
        <main>
            <div className='max-w-main'>
                <FAQ showAll />
            </div>
        </main>
    )
}

export default FAQPage