import React from 'react'
import { terms_and_conditions } from 'utils/constants/terms_and_conditions'

function TermsAndConditions() {
    return (
        <section>
            <div className='max-w-main py-4 sm:py-8 space-y-4 sm:space-y-8'>
                <div className='space-y-2 sm:space-y-4'>
                    <h1 className='text-2xl lg:text-4xl leading-normal font-bold'>Terms And Conditions</h1>
                    <p className='text-sm sm:text-base'>Updated on 24 October 2023</p>
                </div>
                {terms_and_conditions.map((item, i) => (
                    <div className='space-y-2 sm:space-y-4'>
                        {item?.title && (
                            <p className='text-lg sm:text-xl font-bold'>{item.title}</p>
                        )}
                        {item?.description && (
                            <div className='space-y-2 sm:space-y-4 text-sm sm:text-base'>
                                {item.description}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>
    )
}

export default TermsAndConditions