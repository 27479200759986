export const terms_and_conditions = [
    {
        description: (
            <>
                <p>
                    Please read the following terms and conditions carefully before using any software, services, or products provided by Maplexxon - PT Cendekia Edukasi Citra Tritunggal. By accessing or using our software, you agree to abide by these terms and conditions.
                </p>
            </>
        )
    },
    {
        title: "1. Acceptance of Terms",
        description: (
            <>
                <p>
                    By accessing or using our software, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use our software.
                </p>

            </>
        )
    },
    {
        title: "2. License and Use",
        description: (
            <>
                <p>
                    <span className="font-semibold">2.1. License Grant:</span>&nbsp;
                    Maplexxon- PT Cendekia Edukasi Citra Tritunggal grants you a non-exclusive, non-transferable, limited license to use the software in accordance with these terms.
                </p>
                <p>
                    <span className="font-semibold">2.2. Prohibited Actions:</span>&nbsp;
                    You agree not to reverse engineer, modify, or attempt to gain unauthorized access to the software. Any unauthorized use or breach of these terms may result in the termination of your license.
                </p>
            </>
        )
    },
    {
        title: "3. Account Registration and Security",
        description: (
            <>
                <p>
                    <span className="font-semibold">3.1. Account Information:</span>&nbsp;
                    When registering for an account and subscribing to our service, you agree to provide accurate and complete information. You are responsible for maintaining the confidentiality of your account information
                </p>
                <p>
                    <span className="font-semibold">3.2. Security:</span>&nbsp;
                    You are responsible for all activities that occur under your account. If you suspect any unauthorized use of your account, you must notify us immediately.
                </p>
            </>
        )
    },
    {
        title: "4. Privacy",
        description: (
            <>
                <p>
                    <span className="font-semibold">4.1. Data Collection:</span>&nbsp;
                    By using our software, you consent to the collection and use of your data in accordance with our Privacy Policy.
                </p>
                <p>
                    <span className="font-semibold">4.2. Data Security:</span>&nbsp;
                    We take reasonable measures to secure your data, but we cannot guarantee the security of transmitted information. Use the software at your own risk.
                </p>
            </>
        )
    },
    {
        title: "5. Payment and Billing",
        description: (
            <>
                <p>
                    <span className="font-semibold">5.1. Payment Terms:</span>&nbsp;
                    If applicable, payment terms will be outlined in a separate agreement. Failure to pay fees may result in the suspension or termination of your access to the software.
                </p>
                <p>
                    <span className="font-semibold">5.2. Taxes:</span>&nbsp;
                    You are responsible for any applicable taxes related to your use of our software.
                </p>
            </>
        )
    },
    {
        title: "6. Termination",
        description: (
            <>
                <p>
                    We reserve the right to terminate or suspend your access to the software at any time, with or without cause. Upon termination, you must cease all use of the software and destroy any related materials.
                </p>
            </>
        )
    },
    {
        title: "7. Changes to Terms",
        description: (
            <>
                <p>
                    We may update these terms from time to time. It is your responsibility to review these terms periodically. Your continued use of the software after changes constitutes acceptance of the updated terms.
                </p>
            </>
        )
    },
    {
        title: "8. Disclaimer of Warranties",
        description: (
            <>
                <p>
                    The software is provided "as is" without warranties of any kind. We disclaim all warranties, whether express or implied, including but not limited to fitness for a particular purpose.
                </p>
            </>
        )
    },
    {
        title: "9. Limitation of Liability",
        description: (
            <>
                <p>
                    In no event shall Maplexxon- PT Cendekia Edukasi Citra Tritunggal be liable for any indirect, incidental, special, or consequential damages, arising out of or in connection with the use or inability to use the software.
                </p>
            </>
        )
    },
    {
        title: "10. Governing Law",
        description: (
            <>
                <p>
                    These terms and conditions shall be governed by and construed in accordance with the laws of Indonesia. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in West Jakarta, Indonesia.
                </p>
                <p>
                    By using our software, you agree to these terms and conditions. If you have any questions or concerns, please contact Maplexxon Legal Support by reaching us to +62 813 8589 8240.
                </p>
            </>
        )
    },
]